<template>
  <v-card class="partnerModal">
    <v-card-title>
      <h4>Ajout de signature</h4>
      <v-spacer />
    </v-card-title>
    <v-card-text>
      <div id="app">
        <VueSignaturePad
          class="sign-pad"
          width="100%"
          height="300px"
          ref="signaturePad"
          :options="{
            onBegin: () => {
              $refs.signaturePad.resizeCanvas();
            },
          }"
        />
        <div class="sign-btn">
          <v-btn class="btn-canceled" depressed @click="cancel">Annuler</v-btn>

          <v-btn class="btn-erase" depressed @click="clear">
            <v-icon left dense>mdi-eraser</v-icon>Effacer</v-btn
          >
          <v-btn class="btn-save" depressed color="primary" @click="save"
            >Valider</v-btn
          >
        </div>
      </div></v-card-text
    >
  </v-card>
</template>
<script>
import { activitiesStore, productsStore } from "@/store";
export default {
  name: "SignatureModal",
  props: {
    showSignatureModal: { type: Boolean },
  },
  data() {
    return {
      isSignaturePadOpen: false,
      signatureOptions: {
        penColor: "rgb(0, 0, 0)",
      },
      signatureImage: "", // Stockage de l'image en base64
    };
  },
  methods: {
    clear() {
      this.$refs.signaturePad.clearSignature();
    },
    async save() {
      const dataUrl = this.$refs.signaturePad.saveSignature();
      if (dataUrl.data !== "data:,") {
        const productId = productsStore.currentId;
        const activity = activitiesStore.currentActivity;
        this.signatureImage = dataUrl.data;
        const files = {};
        files["file64"] = this.signatureImage;

        await activitiesStore.addImage({
          id: activity.id,
          productId,
          signature: { data: this.signatureImage, typeSignature: "base64" },
          files,
        });
      }
      this.$refs.signaturePad.clearSignature();
      this.$emit("close");
    },
    cancel() {
      this.clear();
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~vuetify/src/styles/settings/_variables";

.sign-pad {
  background: white;
  border: 1px solid;
  border-color: var(--v-primary-base);
}
.sign-btn {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 3%;
}
.v-btn {
  margin-right: 10px;
}
.btn-save {
  margin-right: 0;
}
.btn-erase {
  background-color: #808080 !important;
  color: white;
}
.btn-canceled {
  color: var(--v-primary-base);
  border: 1px solid var(--v-primary-base);
  background-color: white !important;
}
</style>
