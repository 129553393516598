









































































































import EditableTable, {
  EditableTableRef,
  TableHeaderItems,
} from "@/components/atom/table/EditableTable.vue";
import { VForm } from "@/models";
import {
  AccountingCarryForwardItem,
  accountingResultLinesToDisplay,
  getLineTypeLabel,
  getIndexByType,
} from "@/models/AccountingCarryForward.model";
import router from "@/router";
import { ROUTE_NAMES } from "@/router/routes";
import {
  accountingCarryForwardsStore,
  accountingPeriodsStore,
  accountingResultsStore,
} from "@/store";
import {
  AccountingCarryForward,
  accountingCarryForwardDefaultLineTypes,
  AccountingCarryForwardLineType,
  AccountingCarryForwardType,
  AccountingResult,
  accountingResultDefaultLineTypes,
  AccountingResultLineType,
  Direction,
  getFirstAccountingPeriod,
  getMoment,
  TaxDeclaration2033LineNumber,
  TaxRegime,
} from "@edmp/api";
import {
  computed,
  ComputedRef,
  defineComponent,
  onBeforeMount,
  PropType,
  ref,
  watch,
} from "@vue/composition-api";
import Decimal from "decimal.js-light";
import { cloneDeep } from "lodash";
import Vue from "vue";

export default defineComponent({
  name: "AccountingCarryForwardTable",
  components: {
    EditableTable,
  },
  props: {
    accountingData: {
      type: Array as PropType<AccountingCarryForward[]>,
      required: true,
    },
    openAmortisationEdit: { type: Boolean },
    openDeficitEdit: { type: Boolean },
  },
  setup(props, context) {
    const accountingCarryForwards = computed(() => props.accountingData);
    const accountingCarryForwardRecovery: ComputedRef<
      | (AccountingCarryForward & {
          type: AccountingCarryForwardType.RECOVERY;
        })
      | undefined
    > = computed(() => {
      return accountingCarryForwardsStore.getAccountingCarryForwardRecovery;
    });
    const accountingPeriods = computed(() =>
      cloneDeep(accountingPeriodsStore.accountingPeriods).sort(
        (a, b) => getMoment(a.startAt).unix() - getMoment(b.startAt).unix()
      )
    );
    const loading = ref(true);
    const isOpenAmortisationEdit = ref(props.openAmortisationEdit);
    const isOpenDeficitEdit = ref(props.openDeficitEdit);
    const hasRecovery = computed(
      () => accountingCarryForwardRecovery.value !== undefined
    );
    const isEditable = computed(() => {
      return (
        hasRecovery.value &&
        !getFirstAccountingPeriod(accountingPeriods.value).closed
      );
    });
    const getAmountForTypeAndPeriod = (
      type: AccountingCarryForwardLineType,
      accountingPeriodId: string
    ) => {
      let amount = 0;
      accountingCarryForwards.value.forEach((cf) => {
        if (
          cf.type === AccountingCarryForwardType.CLOSURE &&
          cf.accountingPeriodId === accountingPeriodId
        ) {
          cf.lines.forEach((line) => {
            if (line.type === type) {
              amount = line.amount;
            }
          });
        }
      });
      return amount;
    };
    const getAmountRecoveryForType = (type: AccountingCarryForwardLineType) => {
      let amount = 0;
      accountingCarryForwardRecovery.value?.lines.forEach((line) => {
        if (line.type === type) {
          amount = line.amount;
        }
      });
      return amount;
    };
    const onInformationIconClick = (
      type: AccountingCarryForwardLineType | AccountingResultLineType
    ) => {
      switch (type) {
        case AccountingResultLineType.DEPRECIATION_AND_AMORTISATION:
        case AccountingResultLineType.DEDUCTIBLE_DEPRECIATION:
          router.push({
            name: ROUTE_NAMES.TaxDeclaration2033,
            query: {
              lineNumberToScroll: TaxDeclaration2033LineNumber.L254,
            },
          });
          break;
        case AccountingResultLineType.NON_DEDUCTIBLE_DEPRECIATION:
          router.push({
            name: ROUTE_NAMES.TaxDeclaration2033,
            query: {
              lineNumberToScroll: TaxDeclaration2033LineNumber.L318,
            },
          });
          break;
        case AccountingResultLineType.ACCOUNTING_RESULT:
          router.push({ name: ROUTE_NAMES.AccountingResult });
          break;
        case AccountingResultLineType.TAX_RESULT_BEFORE_ALLOCATION:
        case AccountingResultLineType.TAX_RESULT_AFTER_ALLOCATION:
          router.push({
            name: ROUTE_NAMES.TaxDeclaration2033,
            query: {
              lineNumberToScroll: TaxDeclaration2033LineNumber.L352,
            },
          });
          break;
        case AccountingCarryForwardLineType.TOTAL_DEFICIT:
          router.push({
            name: ROUTE_NAMES.TaxDeclaration2033,
            query: {
              lineNumberToScroll: TaxDeclaration2033LineNumber.L982,
            },
          });
          break;
        default:
          break;
      }
    };

    const headers: ComputedRef<TableHeaderItems> = computed(() => {
      const headersOut = [{ text: "", value: "type" }];

      if (accountingCarryForwardRecovery.value) {
        headersOut.push({
          text: new Date(accountingCarryForwardRecovery.value.startAt)
            .getFullYear()
            .toString(),
          value: "recovery",
        });
      }

      let years = accountingCarryForwards.value.flatMap((cf) => {
        if (
          cf.type === AccountingCarryForwardType.CLOSURE &&
          cf.accountingPeriodId
        ) {
          const period = accountingPeriods.value.find(
            (acc) => acc.id === cf.accountingPeriodId
          );
          return period ? new Date(period.startAt).getFullYear() : [];
        } else {
          return [];
        }
      });

      years = Array.from(new Set(years)).sort();

      years.forEach((year) => {
        headersOut.push({
          text: year.toString(),
          value: "values" + year,
        });
      });

      return headersOut;
    });

    const taxRegime = computed(
      () =>
        accountingPeriodsStore.currentAccountingPeriod?.taxRegime ||
        TaxRegime.IS_2065
    );
    const accountingCarryForwardLineTypes = computed(() => {
      return accountingCarryForwardDefaultLineTypes[taxRegime.value];
    });
    const accountingResultLineTypes = computed(() => {
      return accountingResultDefaultLineTypes[taxRegime.value];
    });
    const accountingResults = ref(new Map<string, AccountingResult>());
    const items = computed(() => {
      loading.value = true;
      const items: AccountingCarryForwardItem[] =
        accountingResultLineTypes.value
          .filter((type) => accountingResultLinesToDisplay.has(type))
          .map((type) => {
            let item: AccountingCarryForwardItem = {
              type: type,
              amounts: accountingPeriods.value.map((period) => {
                const line = accountingResults.value
                  .get(period.id)
                  ?.lines.find((line) => line.type === type);
                let amount;
                if (
                  (type === AccountingResultLineType.OPERATING_RESULT ||
                    type === AccountingResultLineType.ACCOUNTING_RESULT ||
                    type ===
                      AccountingResultLineType.TAX_RESULT_BEFORE_ALLOCATION ||
                    type ===
                      AccountingResultLineType.TAX_RESULT_AFTER_ALLOCATION) &&
                  line?.direction === Direction.debit
                ) {
                  amount = -line.amount;
                } else {
                  amount = line?.amount;
                }
                return amount ?? 0;
              }),
            };
            return item;
          });

      items.push(
        ...accountingCarryForwardLineTypes.value.map((type) => {
          let item: AccountingCarryForwardItem = {
            type: type,
            amounts: accountingPeriods.value.map((period) =>
              getAmountForTypeAndPeriod(type, period.id)
            ),
          };
          if (accountingCarryForwardRecovery.value) {
            const amount = getAmountRecoveryForType(type);
            item.recoveryAmount = amount
              ? amount === -1
                ? ""
                : amount.toString()
              : "0";
          }
          return item;
        })
      );
      loading.value = false;
      return items.sort((a, b) => {
        return getIndexByType(a.type) - getIndexByType(b.type);
      });
    });
    const rebuildAccountingResultsMap = async () => {
      const newMap = new Map();

      for (const period of accountingPeriods.value) {
        const result =
          accountingResultsStore.getAccountingResultByAccountingPeriod(
            period.id
          );
        if (result) {
          newMap.set(period.id, result);
        }
      }
      accountingResults.value = newMap;
    };
    watch(
      [
        () => accountingPeriodsStore.accountingPeriods,
        () => accountingResultsStore.accountingResults,
        () => accountingCarryForwardsStore.accountingCarryForwards,
      ],
      async () => {
        await rebuildAccountingResultsMap();
      },
      {
        deep: true,
      }
    );

    watch(
      () => accountingCarryForwards.value,
      () => {
        for (let index = 0; index < items.value.length; index++) {
          if (
            !isEditable.value ||
            (items.value[index].type !==
              AccountingCarryForwardLineType.TOTAL_DEFICIT &&
              items.value[index].type !==
                AccountingCarryForwardLineType.TOTAL_DEPRECIATION)
          ) {
            (context.refs.editableTable as EditableTableRef).updateHideAction(
              index,
              { edit: true, cancelEdit: true, delete: true, validate: true }
            );
          } else {
            (context.refs.editableTable as EditableTableRef).updateHideAction(
              index,
              { edit: false, cancelEdit: false, delete: true, validate: false }
            );
            if (
              items.value[index].type ===
                AccountingCarryForwardLineType.TOTAL_DEPRECIATION &&
              isOpenAmortisationEdit.value
            ) {
              (context.refs.editableTable as EditableTableRef).updateIsEditing(
                index,
                true
              );
            }
            if (
              items.value[index].type ===
                AccountingCarryForwardLineType.TOTAL_DEFICIT &&
              isOpenDeficitEdit.value
            ) {
              (context.refs.editableTable as EditableTableRef).updateIsEditing(
                index,
                true
              );
            }
          }
        }
      },
      { deep: false }
    );

    const firstYear = computed(() => Number(headers.value[2].text ?? 0));

    const validateItem = async ({ rowIndex }: { rowIndex: number }) => {
      if (
        (context.refs.form as VForm).validate() &&
        accountingCarryForwardRecovery.value
      ) {
        const type = items.value[rowIndex].type;
        const value = items.value[rowIndex].recoveryAmount;
        const carryForwardLineToSave =
          accountingCarryForwardRecovery.value.lines.find(
            (line) => line.type === type
          );
        if (!carryForwardLineToSave || !value) {
          return;
        }
        const carryForwardToSave = Object.assign(
          cloneDeep(accountingCarryForwardRecovery.value),
          {
            lines: [
              ...cloneDeep(accountingCarryForwardRecovery.value).lines.filter(
                (line) => line.type !== type
              ),
              {
                ...carryForwardLineToSave,
                amount: Number(new Decimal(value).abs().toFixed(0)),
                direction: new Decimal(value).isNegative()
                  ? Direction.debit
                  : Direction.credit,
              },
            ],
          }
        );

        try {
          await accountingCarryForwardsStore.updateAccountingCarryForward(
            carryForwardToSave
          );
          (context.refs.editableTable as EditableTableRef).updateIsEditing(
            rowIndex,
            false
          );
          // Use fetch to retrieve the next carry forward data, including computed amounts
          accountingResultsStore.fetchAccountingResults();
          accountingCarryForwardsStore.fetchAccountingCarryForwards();
          context.root.$router.push({ query: {} });
          isOpenAmortisationEdit.value = false;
          isOpenDeficitEdit.value = false;
        } catch (error) {
          console.log(error);
          (context.refs.editableTable as EditableTableRef).updateIsEditing(
            rowIndex,
            true
          );
        }
      }
    };

    const cancelEditItem = ({ rowIndex }: { rowIndex: number }) => {
      // items.value[rowIndex].recoveryAmount = "";
      if (accountingCarryForwardRecovery.value) {
        Vue.set(
          accountingCarryForwardRecovery.value,
          rowIndex,
          cloneDeep(accountingCarryForwardRecovery.value)
        );
      }
    };

    onBeforeMount(async () => {
      await accountingResultsStore.fetchAccountingResults();
      rebuildAccountingResultsMap();
    });

    return {
      AccountingCarryForwardLineType,
      AccountingResultLineType,
      headers,
      items,
      loading,
      firstYear,
      validateItem,
      cancelEditItem,
      getLineTypeLabel,
      onInformationIconClick,
      hasRecovery,
      isEditable,
    };
  },
});
