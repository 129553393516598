export const RegularizationEventType = "RegularizationEvent" as const;

export enum RegularizationEventCode {
  ENTER_REGULARIZATION_INTERFACE = "enterRegularizationInterface",
  ENTER_REGULARIZATION_INTERFACE_ERROR = "enterRegularizationInterfaceError",
  SUBMIT_REGULARIZATION = "submitRegularization",
  OPEN_REGULARIZATION_NOTICE = "openRegularizationNotice",
  ENTER_REGULARIZATION_HISTORY_INTERFACE = "enterRegularizationHistoryInterface",
  DOWNLOAD_REGULARIZATION_TEMPLATE_WORD = "downloadRegularizationTemplateWord",
  DOWNLOAD_REGULARIZATION_TEMPLATE_PDF = "downloadRegularizationTemplatePdf",
  REQUIRES_PREMIUM_OPTIMUM = "requiresPremiumOptimum",
  CLICK_REGULARIZATION_SEND_MAIL = "clickRegularizationSendMail",
}

export type RegularizationEventData<
  Code extends RegularizationEventCode = RegularizationEventCode
> = {
  code: Code;
  userId: string;
  productId: string;
  date: string;
} & (
  | { code: RegularizationEventCode.ENTER_REGULARIZATION_INTERFACE }
  | { code: RegularizationEventCode.ENTER_REGULARIZATION_INTERFACE_ERROR }
  | { code: RegularizationEventCode.SUBMIT_REGULARIZATION }
  | { code: RegularizationEventCode.OPEN_REGULARIZATION_NOTICE }
  | { code: RegularizationEventCode.ENTER_REGULARIZATION_HISTORY_INTERFACE }
  | { code: RegularizationEventCode.DOWNLOAD_REGULARIZATION_TEMPLATE_WORD }
  | { code: RegularizationEventCode.DOWNLOAD_REGULARIZATION_TEMPLATE_PDF }
  | { code: RegularizationEventCode.REQUIRES_PREMIUM_OPTIMUM }
  | { code: RegularizationEventCode.CLICK_REGULARIZATION_SEND_MAIL }
);

export type RegularizationEvent = CustomEvent<RegularizationEventData>;

export function dispatchRegularizationEvent(data: RegularizationEventData) {
  dispatchEvent(
    new CustomEvent<RegularizationEventData>(RegularizationEventType, {
      detail: data,
    })
  );
}
