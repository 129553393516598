















































































































import { activitiesStore, documentsStore, productsStore } from "@/store";
import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  watch,
} from "@vue/composition-api";
import { ref, Ref } from "@vue/composition-api";
import SignatureModal from "../activities/SignatureModal.vue";

export default defineComponent({
  name: "SignatureCard",
  components: {
    SignatureModal,
  },
  setup() {
    const isLoading: Ref<boolean> = ref(false);
    const isEditingFile: Ref<boolean> = ref(false);
    const isEditingPad: Ref<boolean> = ref(false);
    const fileSignature: Ref<File | null> = ref(null);
    const deleteFileInProgress: Ref<boolean> = ref(false);

    const showSignatureModal: Ref<boolean> = ref(false);
    const productId = computed(() => {
      return productsStore.currentId;
    });
    const activity = computed(() => {
      return activitiesStore.currentActivity;
    });
    const files = {};
    const imageSignaturePad = computed(() => {
      if (activity.value?.signature?.typeSignature === "base64") {
        return activity.value?.signature?.data;
      }
    });
    const imageSignatureFile = computed(() => {
      if (activity?.value?.signature?.signatureId) {
        return documentsStore.getDocument(activity.value.signature.signatureId);
      }
    });
    //For console error Invalid prop: type check failed for prop "disabled". Expected Boolean, got Object
    const fileInput: Ref<HTMLElement | null> = ref(null); // Référence à l'élément v-file-input

    const fileSignatureRules = (
      fileSignature: File
    ): {
      valid: boolean;
      name: string;
      message: string;
    } => {
      if (
        fileSignature.type !== "image/png" &&
        fileSignature.type !== "image/jpeg"
      ) {
        return {
          valid: false,
          name: "TYPE ERROR",
          message:
            "Format de fichier non supporté. Seuls les formats JPEG et PNG sont acceptés.",
        };
      }
      if (fileSignature?.size >= 2000000) {
        return {
          valid: false,
          name: "SIZE ERROR",
          message: `Le fichier dépasse la taille maximale autorisée de 2 Mo. Veuillez choisir un fichier plus petit.`,
        };
      }
      return { valid: true, name: "OK", message: "Aucune erreur" };
    };
    function closeSignatureModal() {
      showSignatureModal.value = false;
      isEditingPad.value = false;
    }
    function closeEditing() {
      isEditingFile.value = false;
      isEditingPad.value = false;
    }
    async function deleteImage() {
      if (activity.value?.id && activity.value.signature) {
        await activitiesStore.deleteImage({
          id: activity.value.id,
          signature: activity.value.signature,
        });
        delete activity.value.signature;
      }
    }

    async function validate() {
      isLoading.value = true;
      if (activity.value) {
        if (activity.value.signature?.signatureId) {
          deleteImage();
        }
        files["signature"] = fileSignature;
        await activitiesStore.addImage({
          id: activity.value.id,
          productId: productId.value,
          files,
        });
        fileSignature.value = null;
      }
      closeEditing();
      showSignatureModal.value = false;
      isLoading.value = false;
    }
    function cancel() {
      fileSignature.value = null;
    }

    const init = async () => {
      await productsStore.switchProduct({ id: productId.value });
      activity.value?.signature?.typeSignature === "image"
        ? isEditingFile
        : activity.value?.signature?.typeSignature === "base64"
        ? isEditingPad
        : !isEditingFile && !isEditingPad;
    };

    watch(
      () => [activitiesStore.currentActivity],
      () => init(),
      { deep: true }
    );

    onBeforeMount(async () => await init());

    onMounted(() => {
      fileInput.value?.click();
    });

    return {
      isLoading,
      activity,
      fileSignature,
      showSignatureModal,
      validate,
      cancel,
      fileSignatureRules,
      closeSignatureModal,
      imageSignaturePad,
      imageSignatureFile,
      deleteImage,
      deleteFileInProgress,
      isEditingPad,
      isEditingFile,
      closeEditing,
      fileInput,
    };
  },
});
