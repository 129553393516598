






























































































































































































































































































import {
  computed,
  defineComponent,
  Ref,
  ref,
  watch,
} from "@vue/composition-api";
import {
  BankAccount,
  statesOther,
  statesReconnect,
  stateIncomplete,
  stateVerified,
  TaxRegime,
  ProductsModel,
} from "@edmp/api";
import { bankAccountsService, banksService } from "@/services";
import { accountingPeriodsStore, productsStore, usersStore } from "@/store";
import {
  HelpingView,
  HelpingMessage,
  HelpingItem,
} from "@/components/atom/helping";
import Title from "@/components/core/title/Title.vue";
import ReconnectBankAccountsModal from "./ReconnectBankAccountsModal.vue";
import ManageBankAccountModal from "./ManageBankAccountModal.vue";
import i18n from "@/plugins/i18n";
import { BankAccountState } from "@/models";
import { ROUTE_NAMES } from "@/router/routes";

export default defineComponent({
  name: "BankAccountDetails",
  components: {
    Title,
    ReconnectBankAccountsModal,
    HelpingMessage,
    ManageBankAccountModal,
    HelpingView,
  },
  props: {
    productId: {
      type: String,
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
  },
  setup(prop, context) {
    const isLoading: Ref<boolean> = ref(true);
    const user = computed(() => usersStore.loggedInUser);

    const isLMNP = computed(
      () =>
        accountingPeriodsStore.currentAccountingPeriod?.taxRegime ===
        TaxRegime.LMNP_2031
    );

    const helpingItems: HelpingItem[] = [
      {
        title: `Comment Ownily sécurise vos données ?`,
        icon: "mdi-lock",
        url: `https://ownily.crisp.help/fr/article/comment-ownily-securise-vos-donnees-1nmlwqt/`,
      },
      {
        title: `Comment rétablir la synchronisation de mon compte bancaire ?`,
        icon: "mdi-sync",
        url: `https://ownily.crisp.help/fr/article/comment-retablir-la-synchronisation-de-mon-compte-bancaire-1hs9557/`,
      },
      {
        title: `Synchronisation bancaire : lexique des erreurs`,
        icon: "mdi-help-circle-outline",
        url: `https://ownily.crisp.help/fr/article/synchronisation-bancaire-lexique-des-erreurs-ik7tk5/`,
      },
    ];

    const bankAccounts: Ref<BankAccountState[]> = ref([]);
    const expandAccounts: Ref<BankAccountState[]> = ref([]);

    const product = ref(
      productsStore.products.find((c) => c.id === prop.productId) as
        | ProductsModel.Product
        | undefined
    );

    const urlLogo: Ref<string> = ref("");

    const isStateVerified = (state: string) => state === stateVerified;
    const isStateIncomplete = (state: string) => state === stateIncomplete;
    const isStateReconnect = (state: string) =>
      statesReconnect.some((value) => value === state);
    const isStateOther = (state: string) =>
      statesOther.some((value) => value === state);

    async function init() {
      isLoading.value = true;
      urlLogo.value = (await banksService.getConfig()).logoUrl;
      await usersStore.fetchLoggedInUser();
      let accounts: BankAccount[] = [];

      if (prop.id) {
        accounts = [await bankAccountsService.get({ id: prop.id })];
      } else if (prop.productId) {
        accounts = await bankAccountsService.list({
          productId: prop.productId,
        });
      } else {
        accounts = await bankAccountsService.list({});
      }
      accounts = accounts.filter(({ bankId }) => bankId !== "Manual");

      // Check if bank accounts exist
      if (accounts.length > 0) {
        bankAccounts.value = accounts.map((acc) => {
          const bank = usersStore.loggedInUser.banks?.find(
            (a) => a.id === acc.bankId
          );
          const bankReference = usersStore.loggedInUser.bankReferences?.find(
            (a) => a.bi_connection === acc.bankConnection
          );
          const bankState = bank?.bi_state ?? stateIncomplete;

          return {
            ...acc,
            activityName: bankReference
              ? productsStore.getProduct({
                  id: bankReference.productId,
                })?.name
              : "Inconue",
            state: bankState,
            canExpand: true,
          };
        });
      }

      // Retrieve Bank synchronized without BankAccount
      const allAccounts = await bankAccountsService.list({});
      const bankAccountsOrphan =
        usersStore.loggedInUser.banks
          ?.filter(
            (bank) => !allAccounts.some((value) => value.bankId === bank.id)
          )
          .map((bank) => {
            return {
              id: "",
              name: "Aucun compte",
              bankName: bank.name,
              bankId: bank.id,
              bankLogoUuid: bank.bi_connector_uuid,
              lastSeen: bank.lastSync,
              state: stateIncomplete,
            };
          }) ?? [];

      bankAccounts.value = [
        ...bankAccounts.value,
        ...bankAccountsOrphan,
      ].filter(({ bankId }) => bankId !== "Manual");

      expandAccounts.value = bankAccounts.value.filter((value) =>
        isStateOther(value.state)
      );

      isLoading.value = false;
    }

    const openDialogReconnect: Ref<boolean> = ref(false);
    const openDialogManage: Ref<boolean> = ref(false);
    const currentAccount: Ref<string | undefined> = ref(prop.id);

    function openModalReconnect(id: string) {
      currentAccount.value = id;
      openDialogReconnect.value = true;
    }

    function openModalManage(id: string) {
      currentAccount.value = id;
      openDialogManage.value = true;
    }

    function showErrorMessage(state: string) {
      const exist = i18n.te("bankaccount.synchronization.message." + state);

      if (exist) {
        return i18n.t("bankaccount.synchronization.message." + state) as string;
      } else {
        return i18n.t("bankaccount.synchronization.message.other") as string;
      }
    }

    function closeDialog() {
      currentAccount.value = undefined;
      openDialogReconnect.value = false;
      openDialogManage.value = false;
    }

    const showCalendly = ref(false);
    const openCalendly = (value: boolean) => {
      showCalendly.value = value;
    };

    function accountsChanged() {
      closeDialog();
      init();
    }
    watch(
      () => productsStore.currentId,
      () => context.root.$router.push("/activities/?focus=banks-section"),
      { deep: true }
    );

    init();

    return {
      user,
      ROUTE_NAMES,
      isLoading,
      isLMNP,
      product,
      helpingItems,
      bankAccounts,
      expandAccounts,
      currentAccount,
      openModalReconnect,
      openModalManage,
      openDialogReconnect,
      openDialogManage,
      showErrorMessage,
      closeDialog,
      accountsChanged,
      urlLogo,
      isStateVerified,
      isStateReconnect,
      isStateOther,
      isStateIncomplete,

      showCalendly,
      openCalendly,
    };
  },
});
