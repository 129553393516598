





















































import { computed, defineComponent, ref, Ref } from "@vue/composition-api";

import Modal from "@/components/atom/Modal.vue";
import RentalAgreementModal from "@/components/core/rentalAgreements/RentalAgreementModal.vue";
import { RealEstateAsset, RentalAgreement } from "@edmp/api";
import { productsStore, realEstateAssetsStore } from "@/store";
import { ROUTE_NAMES } from "@/router/routes";

export default defineComponent({
  name: "RentalAgreementWithoutRealEstateAssetModal",
  components: {
    Modal,
    RentalAgreementModal,
  },
  props: {
    productId: {
      type: String,
      required: true,
    },
    displayRentalAgreementModalWithoutRealEstateAsset: {
      type: Boolean,
      require: true,
    },
    hidePagination: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const displayRentalAgreementModal = computed(() => {
      return props.displayRentalAgreementModalWithoutRealEstateAsset;
    });

    const selectedRealEstateAsset: Ref<RealEstateAsset | undefined> = ref();

    const selectedRentalAgreement: Ref<RentalAgreement | null> = ref(null);
    const rentalAgreementId: Ref<string | undefined> = ref();

    const realEstateAssets = computed(() => {
      return realEstateAssetsStore.realEstateAssets;
    });

    const confirm = () => {
      if (selectedRealEstateAsset.value) {
        realEstateAssetsStore.selectRealEstateAssetId(
          selectedRealEstateAsset.value.id
        );
        context.root.$router.push({
          name: ROUTE_NAMES.RentalAgreementFlow,
          params: {
            productId: productsStore.currentId,
            realEstateAssetId: selectedRealEstateAsset.value?.id,
            rentalAgreementId: "new",
          },
        });
      }
    };

    return {
      rentalAgreementId,
      realEstateAssets,
      displayRentalAgreementModal,
      selectedRealEstateAsset,
      selectedRentalAgreement,
      ROUTE_NAMES,
      confirm,
    };
  },
});
