































































































































































import { computed, defineComponent, PropType, ref } from "@vue/composition-api";
import confetti from "canvas-confetti";
import SectionTitle from "@/components/atom/SectionTitle.vue";
import DatePicker from "@/components/atom/DatePicker.vue";
import YesNoSwitch from "@/components/atom/switch/YesNoSwitch.vue";
import Dialog from "@/components/atom/Dialog.vue";
import HelpingMessage from "@/components/atom/helping/HelpingMessage.vue";
import { Regularization } from "@edmp/api/src";
import { DocumentModelType, getMoment } from "@edmp/api";
import { productsStore, rentalAgreementsStore, usersStore } from "@/store";
import { rentalsService } from "@/services";
import EditableTable from "@/components/atom/table/EditableTable.vue";
import { dispatchRegularizationEvent, RegularizationEventCode } from "@/events";

export default defineComponent({
  name: "RegularizationResult",
  components: {
    Dialog,
    YesNoSwitch,
    DatePicker,
    SectionTitle,
    HelpingMessage,
    EditableTable,
  },
  props: {
    regularization: {
      type: Object as PropType<Regularization & { data: Object }>,
      required: true,
    },
  },

  setup(props, context) {
    const goBack = () => {
      context.emit("goBack");
    };

    const months = computed(
      () =>
        getMoment(props.regularization.periodEnd).month() +
        1 -
        getMoment(props.regularization.periodStart).month() +
        (getMoment(props.regularization.periodEnd).year() -
          getMoment(props.regularization.periodStart).year()) *
          12
    );

    const rentalAgreement = computed(() =>
      rentalAgreementsStore.getRentalAgreement(
        props.regularization.rentalAgreementId
      )
    );

    const provisionsOverTheYear = computed(() => {
      if (rentalAgreement.value?.financialConditions)
        return (
          rentalAgreement.value?.financialConditions.amountOfCharges *
          months.value
        ).toFixed(2);
      else {
        return "0.00";
      }
    });

    const totalCharges = computed(() => {
      return Object.keys(props.regularization.data)
        .reduce((total, chargeType) => {
          return (
            total +
            Object.keys(props.regularization.data[chargeType]).reduce(
              (total, currentProperty) => {
                return Number(
                  total +
                    Number(
                      props.regularization.data[chargeType][currentProperty]
                        .totalAmount
                    )
                );
              },
              0
            )
          );
        }, 0)
        .toFixed(2);
    });

    const result = computed(() => {
      if (provisionsOverTheYear.value && totalCharges.value) {
        return (
          Number(provisionsOverTheYear.value) - Number(totalCharges.value)
        ).toFixed(2);
      } else {
        return "0.00";
      }
    });

    const isResultNegative = computed(() => result.value.slice(0, 1) === "-");

    const resultText = computed(() => {
      return Math.abs(Number(result.value)).toString();
    });

    const headersRegularizationResult = [
      { text: "Rubrique", value: "section" },
      { text: "Montant (€)", value: "amount" },
    ];

    const itemsRegularizationResult = computed(() => [
      {
        section: "Montant des provisions mensuelles",
        amount: `${rentalAgreement.value?.financialConditions.amountOfCharges} €`,
      },
      {
        section: `Total des provisions pour la période (${months.value} mois)`,
        amount: `${provisionsOverTheYear.value} €`,
      },
      {
        section: `Total des charges réelles sur la période (${months.value} mois)`,
        amount: `${totalCharges.value} €`,
      },
      {
        section: "Montant de la régularisation",
        amount: `<strong class="${
          isResultNegative.value ? "greenText" : "redText"
        }">${resultText.value} € ${
          isResultNegative.value ? "à récupérer" : "à payer"
        }</strong>`,
        highlight: true,
      },
    ]);

    const isLoading = ref(false);

    const isOpenCongratsModal = ref(false);

    const validateRegularization = async () => {
      isLoading.value = true;
      await rentalsService.agreements.createRegularization({
        productId: productsStore.currentId,
        rentalAgreementId: props.regularization.rentalAgreementId,
        date: getMoment().format("DD/MM/YYYY"),
        provisions: provisionsOverTheYear.value,
        charges: totalCharges.value,
        result: result.value,
      });
      dispatchRegularizationEvent({
        userId: usersStore.loggedInUser.id,
        productId: productsStore.currentId,
        date: getMoment().toISOString(),
        code: RegularizationEventCode.SUBMIT_REGULARIZATION,
      });

      isLoading.value = false;
      confetti({ zIndex: 1000 });

      const defaults = {
        origin: { y: 0.7 },
      };

      isOpenCongratsModal.value = true;

      function fire(particleRatio, opts) {
        confetti({
          ...defaults,
          ...opts,
          particleCount: Math.floor(200 * particleRatio),
        });
      }

      fire(0.25, {
        spread: 26,
        startVelocity: 55,
      });
      fire(0.2, {
        spread: 60,
      });
      fire(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8,
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2,
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 45,
      });
    };

    const downloadRegularizationMailModel = (fileType) => {
      rentalsService.agreements.downloadDocumentModel({
        modelType: DocumentModelType.NOTICE_ADJUSTMENT_OF_CHARGES_TENANT,
        fileType,
      });

      dispatchRegularizationEvent({
        userId: usersStore.loggedInUser.id,
        productId: productsStore.currentId,
        date: getMoment().toISOString(),
        code:
          fileType === "docx"
            ? RegularizationEventCode.DOWNLOAD_REGULARIZATION_TEMPLATE_WORD
            : RegularizationEventCode.DOWNLOAD_REGULARIZATION_TEMPLATE_PDF,
      });
    };

    const clickSendMail = () =>
      dispatchRegularizationEvent({
        userId: usersStore.loggedInUser.id,
        productId: productsStore.currentId,
        date: getMoment().toISOString(),
        code: RegularizationEventCode.CLICK_REGULARIZATION_SEND_MAIL,
      });

    return {
      goBack,
      provisionsOverTheYear,
      validateRegularization,
      totalCharges,
      result,
      resultText,
      isResultNegative,
      isOpenCongratsModal,
      productsStore,
      downloadRegularizationMailModel,
      clickSendMail,
      headersRegularizationResult,
      itemsRegularizationResult,
    };
  },
});
