import { Document, model, Schema, Error } from "mongoose";
import { ulid } from "ulid";
import { EventMetadata } from "./Log.model";
import DeviceDetector from "device-detector-js";
import { UserAccountResponse } from "./UserAccount.model";
import { SpecProvider } from "./Bank.model";
import { Model } from "mongoose";

export type StatsDataLastLogin = {
  username: string;
  device: DeviceDetector.DeviceDetectorResult;
  timestamp: string;
};

export type StatDataLoginSuccess = {
  status: "success";
  username: string;
  sub: string;
  device: DeviceDetector.DeviceDetectorResult;
  payload: UserAccountResponse;
};
export interface StatAccountLogin {
  _id: string;
  value: number;
}

export type StatDataAccountRegister = {
  sub: string;
  username: string;
  scope: string;
};
export interface StatAccountRegister {
  _id: string;
  value: number;
}

export type StatDataBankSynced = {
  sub: string;
  provider: SpecProvider;
  bankAccountIds: string[];
  newBankAccountIds: string[];
  accounts: number;
  newAccounts: number;
  transactions: number;
  newTransactions: number;
  date: string;
};
export interface StatBanksSynced {
  _id: string;
  subs: string[];
  value: number;
}

export type Stat<
  EventName extends "lastlogin.update" | "login.success" | "meta.register" | "bank.synced" =
    | "lastlogin.update"
    | "login.success"
    | "meta.register"
    | "bank.synced"
> = EventMetadata<EventName> & {
  id: string;
  createdAt: string;
  updatedAt: string;
} & (EventName extends "lastlogin.update"
    ? {
        eventName: "lastlogin.update";
        data: StatsDataLastLogin;
      }
    : EventName extends "login.success"
    ? {
        eventName: "login.success";
        data: StatDataLoginSuccess;
      }
    : EventName extends "meta.register"
    ? {
        eventName: "meta.register";
        data: StatDataAccountRegister;
      }
    : EventName extends "bank.synced"
    ? {
        eventName: "bank.synced";
        data: StatDataBankSynced;
      }
    : never);
export type StatCreate<
  EventName extends "lastlogin.update" | "login.success" | "meta.register" | "bank.synced" =
    | "lastlogin.update"
    | "login.success"
    | "meta.register"
    | "bank.synced"
> = Omit<Stat<EventName>, "id" | "createdAt" | "updatedAt">;

const lastLoginStatSchema = new Schema<StatsDataLastLogin>(
  {
    username: { type: String },
    device: {
      client: {
        type: { type: String },
        name: { type: String },
        version: { type: String },
        engine: { type: String },
        engineVersion: { type: String },
      },
      os: {
        name: { type: String },
        version: { type: String },
        platform: { type: String },
      },
      device: {
        type: { type: String },
        brand: { type: String },
        model: { type: String },
      },
      bot: { type: Object },
    },
    timestamp: { type: Date }, // Last Login timestamp
  },
  {
    _id: false,
  }
);

const loginSuccessStatSchema = new Schema<StatDataLoginSuccess>({
  status: { type: String },
  username: { type: String, index: true },
  device: {
    client: {
      type: { type: String },
      name: { type: String },
      version: { type: String },
      engine: { type: String },
      engineVersion: { type: String },
    },
    os: {
      name: { type: String },
      version: { type: String },
      platform: { type: String },
    },
    device: {
      type: { type: String },
      brand: { type: String },
      model: { type: String },
    },
    bot: { type: Object },
  },
  payload: {
    sub: { type: String },
    scope: { type: String },
  },
});

const metaRegisterStatSchema = new Schema<StatDataAccountRegister>({
  sub: { type: String },
  username: { type: String },
  scope: { type: String },
});

const bankSyncedStatSchema = new Schema<StatDataBankSynced>({
  sub: { type: String },
  provider: {
    id: { type: String },
    repository: { type: String },
    redirect_id: { type: String },
    name: { type: String },
    // logo: { type: String },
  },
  stats: {
    accounts: { type: Number },
    newAccounts: { type: Number },
    // newAccountsTransactions: { type: Number },
    // updatedAccountsTransactions: { type: Number },
    newTransactions: { type: Number },
    transactions: { type: Number },
    // bankAccountId: { type: String },
    // provider: { type: String },
    // timestamp: { type: String },
  },
});

export type StatDocument = Stat & Document<string>;
const StatSchema = new Schema<StatDocument>(
  {
    _id: { type: String, default: () => ulid() },
    eventName: { type: String, index: true, required: true },
    sub: { type: String, required: true },
    date: { type: Date, required: true },
    hostname: { type: String },
    env: { type: String },
    pid: { type: Number },
    data: { type: Schema.Types.Mixed },
  },
  {
    timestamps: true,
    toJSON: {
      versionKey: false,
      virtuals: true,
      transform(doc, ret: StatDocument) {
        ret.id = ret._id;
        delete ret._id;
        return ret;
      },
    },
    strict: true,
  }
);
const validate = <T extends Record<string, any>>(
  modelName: string,
  schema: Schema<T, Model<T, any, any>, undefined, Record<never, never>>,
  data: T
): Error.ValidationError | null => {
  const Model = model<T>(modelName, schema); // Utilise le schéma pour valider 'data'
  const instance = new Model(data); // Essaie de créer une instance avec les données
  const validationError = instance.validateSync(); // Utilise la méthode 'validate' de Mongoose pour vérifier si les données sont valides
  return validationError; // Si 'validationError' est nul, cela signifie que les données correspondent au schéma
};
StatSchema.pre("validate", function (next) {
  // Vérifie que 'data' correspond à l'une des structures de données attendues
  const stats = this.toJSON();
  let validationError: Error.ValidationError | null | undefined;

  if (stats.eventName === "lastlogin.update") {
    validationError = validate("StatsDataLastLogin", lastLoginStatSchema, stats.data);
  }
  if (stats.eventName === "login.success") {
    validationError = validate("StatDataLoginSuccess", loginSuccessStatSchema, stats.data);
  }
  if (stats.eventName === "meta.register") {
    validationError = validate("StatDataAccountRegister", metaRegisterStatSchema, stats.data);
  }
  if (stats.eventName === "bank.synced") {
    validationError = validate("StatDataBankSynced", bankSyncedStatSchema, stats.data);
  }

  if (!!validationError) {
    next(validationError);
  }
  next();
});
export const StatsCollection = "Stats";
export const StatModel = model<StatDocument>("Stat", StatSchema, StatsCollection);

// API
export namespace StatsService {
  export type GetAccountLastLoginIn = { username: string };
  export type GetAccountLastLoginOut = StatsDataLastLogin;

  export type GetAccountLoginsIn = { username: string };
  export type GetAccountLoginsOut = StatAccountLogin[];

  export type GetAccountRegistersIn = never;
  export type GetAccountRegistersOut = StatAccountRegister[];

  export type GetBanksSyncedIn = never;
  export type GetBanksSyncedOut = StatBanksSynced[];

  export type GetTaskUserIn = { userId: string };
  export type GetTaskUserOut = {
    completed: number;
    open: number;
    total: number;
    allCompleted: boolean;
  };

  export type GetTransactionCompanyIn = { productId: string };
  export type GetTransactionCompanyOut = { productId: string; manualTransactions: number; operations: number };
}
