





















































































































































































































import {
  computed,
  ComputedRef,
  defineComponent,
  onBeforeMount,
  onMounted,
  ref,
  Ref,
  watch,
} from "@vue/composition-api";
import {
  Partner,
  TaskGroupCode,
  ProductsModel,
  ActivitiesModel,
  SubscriptionsModel,
} from "@edmp/api";

import { ForbiddenError, subject } from "@casl/ability";
import { ability } from "@/services";
import { onlyNumber } from "@/utils";
import {
  usersStore,
  productsStore,
  partnersStore,
  tasksStore,
  coreStore,
  realEstateAssetsStore,
  accountingPeriodsStore,
  subscriptionsStore,
} from "@/store";
import { ArticleEnum, useCrisp } from "@/composables/crisp.usable";

import RealEstateLoanModal from "./realEstateLoans/RealEstateLoanModal.vue";
import RealEstateLoans from "./realEstateLoans/RealEstateLoans.vue";

import BankAccounts from "./bankAccounts/BankAccounts.vue";
import Accounting from "./Accounting.vue";
import RealEstates from "./RealEstates.vue";
import Title from "../title/Title.vue";
import ActivityInfos from "./ActivityInfos.vue";
import ProductMail from "./ProductMail.vue";
import SubscriptionCard from "../subscriptions/list/SubscriptionCard.vue";
import Partners from "./partners/Partners.vue";
import PartnersModal from "./partners/modals/PartnerModal.vue";
import PartnersInvitationModal from "./partners/modals/PartnersInvitationModal.vue";
import ActivityCreateInfosModal from "./ActivityCreateInfosModal.vue";
import TaskGroupResumeNavigateTo from "@/components/core/tasks/TaskGroupResumeNavigateTo.vue";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import { HelpingMessage } from "@/components/atom/helping";
import RealEstateLoansTest from "./realEstateLoans/RealEstateLoans.vue";
import { ROUTE_NAMES } from "@/router/routes";
import SignatureModal from "../activities/SignatureModal.vue";
import SignatureCard from "../activities/SignatureCard.vue";
import LogoCard from "../activities/LogoCard.vue";

export default defineComponent({
  name: "Activity",
  components: {
    Title,
    TaskGroupResumeNavigateTo,
    Accounting,
    BankAccounts,
    RealEstates,
    RealEstateLoans,
    RealEstateLoanModal,
    ActivityInfos,
    ProductMail,
    SubscriptionCard,
    Partners,
    PartnersModal,
    PartnersInvitationModal,
    ActivityCreateInfosModal,
    HelpingMessage,
    RealEstateLoansTest,
    SignatureModal,
    SignatureCard,
    LogoCard,
  },
  props: {
    realEstateLoanId: { type: String, required: false },
    tabIndex: { type: String, required: false },
  },

  setup(props, context) {
    const product = computed(() => productsStore.currentProduct);
    const tabItemIndex = ref(props.tabIndex ? parseInt(props.tabIndex) : 0);
    const isLoading: Ref<boolean> = ref(false);
    const openActivityCreateInfosModal = ref(false);
    const goCreate = () => {
      ForbiddenError.from(ability).throwUnlessCan(
        "add",
        subject("Product", {})
      );
      openActivityCreateInfosModal.value = true;
    };

    // Tasks to do
    const taskGroupCodes = computed(() => {
      const defaultTask = [
        TaskGroupCode.StartWellLMNP,
        TaskGroupCode.StartWell,
        TaskGroupCode.StartWellInformation,
        TaskGroupCode.StartWellAccounting,
        TaskGroupCode.StartWellAccountingLMNP,
      ];
      if (accountingPeriodsStore.currentAccountingPeriod?.openAt) {
        defaultTask.push(TaskGroupCode.EventAccountingPeriodClosure);
      }
      return defaultTask;
    });

    const activity = computed(() => {
      // On affiche la page en lecture/modification
      return Object.assign(
        {
          address: { street: "", city: "", zip: "" },
        },
        {
          ...productsStore.currentProduct?.activity,
        } as ActivitiesModel.Activity
      );
    });

    // Subscription
    const redirectUri = computed(() => `products/${activity.value.id}`);
    const subscription = computed(() => {
      return subscriptionsStore.getCurrentSubscription;
    });
    const isLMNP = computed(() => accountingPeriodsStore.isLMNP);

    // Display the message about energy information only when it is not LMNP, the client is premium, and the client has not modified or added a property since the feature was implemented
    const displayEnergyInformationMessage = computed(() => {
      const isPremiumOrOptimum =
        subscription.value &&
        (subscription.value.plan.type === SubscriptionsModel.PlanType.Premium ||
          subscription.value.plan.type === SubscriptionsModel.PlanType.Optimum);

      const hasInformedEnergyInformation =
        realEstateAssetsStore.realEstateAssets.some(
          (realEstateAsset) => realEstateAsset.dpe?.dpeGrade !== undefined
        );

      return (
        !isLMNP.value && isPremiumOrOptimum && !hasInformedEnergyInformation
      );
    });

    // Biens
    const realEstateNew = () => {
      try {
        ForbiddenError.from(ability).throwUnlessCan(
          "addRealEstateAsset",
          subject("RentalManagement", {
            size: realEstateAssetsStore.realEstateAssets.length,
          })
        );
        const productId = productsStore.currentId;
        context.root.$router.push({
          name: ROUTE_NAMES.RealEstate,
          params: { productId, realEstateAssetId: "new" },
        });
      } catch (error) {
        if (error instanceof ForbiddenError) {
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.WARNING,
            message: error.message,
          });
        }
      }
    };

    // Partner
    const connectedUser = computed(() => usersStore.loggedInUser);
    const partners: ComputedRef<Partner[]> = computed(
      () => partnersStore.partners
    );
    const showPartnerModal: Ref<boolean> = ref(false);

    const selectedPartner = ref(undefined);
    function selectPartner(partner) {
      selectedPartner.value = partner;
      showPartnerModal.value = true;
    }
    function closePartnerModal() {
      showPartnerModal.value = false;
      selectedPartner.value = undefined;
    }
    function validatePartnerModal() {
      closePartnerModal();
      tasksStore.fetchTaskGroups({});
    }
    const showPartnersInvitationModal: Ref<boolean> = ref(false);
    const openPartnersInvitationModal = () => {
      try {
        ForbiddenError.from(ability).throwUnlessCan(
          "invite",
          subject("Product", {})
        );
        showPartnersInvitationModal.value = true;
      } catch (error) {
        if (error instanceof ForbiddenError) {
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.WARNING,
            message: error.message,
          });
        }
      }
    };

    //Loan
    const selectedLoan = ref(undefined);
    function selectLoan(loan) {
      selectedLoan.value = loan;
      openLoanModal.value = true;
    }

    function closeLoanModal() {
      openLoanModal.value = false;
      selectedLoan.value = undefined;
    }
    function validateLoanModal() {
      closeLoanModal();
    }
    const openLoanModal: Ref<boolean> = ref(false);

    // Focus
    watch(
      () => context.root.$route.query,
      (newVal) => {
        try {
          context.root.$vuetify.goTo(`#${newVal["focus"].toString()}`, {
            offset: 60,
          });
          context.root.$route.query["focus"] = "";
        } catch (err) {
          // do nothing
        }
      },
      { deep: true }
    );

    onMounted(async () => {
      if (context.root.$route.query["focus"]) {
        setTimeout(() => {
          try {
            context.root.$vuetify.goTo(
              `#${context.root.$route.query["focus"].toString()}`,
              {
                offset: 60,
              }
            );
            context.root.$route.query["focus"] = "";
          } catch (err) {
            // do nothing
          }
        }, 500);
      }
    });

    // Init
    async function init() {
      isLoading.value = true;
      if (product.value?.id) {
        await productsStore.switchProduct({ id: product.value?.id });
      }
      if (
        productsStore.currentProduct?.status ===
        ProductsModel.ProductStatus.pending
      ) {
        context.root.$router.push({
          name: ROUTE_NAMES.ActivityContinue,
        });
      }

      isLoading.value = false;
    }

    watch(
      () => [
        productsStore.currentProduct,
        accountingPeriodsStore.currentAccountingPeriod,
      ],
      () => init(),
      { deep: true }
    );

    const validateCreation = (newRealEstateAssetId) => {
      if (product.value?.id) {
        context.root.$router.push({
          name: ROUTE_NAMES.RealEstate,
          params: {
            productId: product.value?.id,
            realEstateAssetId: newRealEstateAssetId,
          },
        });
      }
    };
    onBeforeMount(() => init());

    return {
      isLoading,
      goCreate,
      connectedUser,
      activity,
      taskGroupCodes,
      redirectUri,
      partners,
      selectedPartner,
      selectPartner,
      showPartnerModal,
      closePartnerModal,
      showPartnersInvitationModal,
      openPartnersInvitationModal,
      validatePartnerModal,
      onlyNumberRule: onlyNumber,
      openArticleSubscriptionCondition: () =>
        useCrisp().openArticle(ArticleEnum.COMPANY_SETTING),
      realEstateNew,
      openActivityCreateInfosModal,
      openLoanModal,
      closeLoanModal,
      validateLoanModal,
      selectedLoan,
      selectLoan,
      validateCreation,
      tabItemIndex,
      product,
      displayEnergyInformationMessage,
    };
  },
});
