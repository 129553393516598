






































































































































































































































































































































import { defineComponent, PropType } from "@vue/composition-api";
import SectionTitle from "@/components/atom/SectionTitle.vue";
import { YesNoSwitch } from "@/components/atom/switch";
import DatePicker from "@/components/atom/DatePicker.vue";
import { Regularization } from "@edmp/api";
import { VForm } from "@/models";
import EditableTable from "@/components/atom/table/EditableTable.vue";
import TitleNew from "@/components/core/title/TitleNew.vue";
import { useRegularization } from "./regularization.usable";

export default defineComponent({
  name: "RegularizationChargeClearance",
  components: {
    DatePicker,
    YesNoSwitch,
    SectionTitle,
    EditableTable,
    TitleNew,
  },
  props: {
    regularization: {
      type: Object as PropType<Regularization & { data: Object }>,
      required: true,
    },
    isFullYear: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, context) {
    const {
      headersFullYearCharge,
      headersPartialYearCommonCharge,
      headersPartialYearIndividualCharge,
      itemsBaseFullYearCharge,
      itemsBasePartialYearCommonCharge,
      itemsBasePartialYearIndividualCharge,
      itemsFullYearCharge,
      itemsPartialYearCommonCharge,
      itemsPartialYearIndividualCharge,
    } = useRegularization();

    const goBack = () => {
      context.emit("goBack");
    };

    const goNext = () => {
      if ((context.refs.form as VForm).validate()) {
        context.emit("goNext");
      }
    };

    return {
      headersFullYearCharge,
      headersPartialYearCommonCharge,
      headersPartialYearIndividualCharge,
      itemsBaseFullYearCharge,
      itemsBasePartialYearCommonCharge,
      itemsBasePartialYearIndividualCharge,
      itemsFullYearCharge,
      itemsPartialYearCommonCharge,
      itemsPartialYearIndividualCharge,
      goBack,
      goNext,
    };
  },
});
