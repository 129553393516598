












import { accountingPeriodsStore, productsStore, tasksStore } from "@/store";
import { TaskStatus } from "@edmp/api";
import { computed, defineComponent, onBeforeMount } from "@vue/composition-api";
import TaskGroup from "./taskGroup/TaskGroup.vue";

export default defineComponent({
  name: "TasksPage",
  components: { TaskGroup },
  setup() {
    const taskGroups = computed(() => {
      return tasksStore.startWellTaskGroup;
    });

    onBeforeMount(async () => {
      await accountingPeriodsStore.fetchAccountingPeriods(
        productsStore.currentId
      );
      await tasksStore.fetchTaskGroups({});
    });

    const displayTaskForFirstTaskGroupNotCompleted = computed(() =>
      taskGroups.value.map((taskGroup, index, taskGroups) => {
        if (
          taskGroups.every(
            (taskGroup) => taskGroup.status === TaskStatus.WAITING
          )
        ) {
          return false;
        }
        if (taskGroup.status !== TaskStatus.COMPLETED) {
          if (index !== 0) {
            return taskGroups[index - 1].status === TaskStatus.COMPLETED;
          }
          return true;
        }
        return false;
      })
    );

    return {
      taskGroups,
      displayTaskForFirstTaskGroupNotCompleted,
    };
  },
});
