import { computed, Ref, ref } from "@vue/composition-api";
import {
  ChargePaymentMethodEnum,
  FinancialConditions,
  PaymentTermsEnum,
  QuarterPeriodEnum,
  RentIndexationTypeParamEnum,
  RentPayedForEnum,
  RentalAgreementType,
  TenantTypeEnum,
  TenseArea,
  NewTenant,
  NewRentalAgreement,
  RentalAgreement,
  Tenant,
} from "@edmp/api";
import { onlyNumber } from "@/utils";
import { useEmail } from "@/composables";

export function useRents() {
  const defaultRentalAgreement: NewRentalAgreement = {
    product: {
      id: "",
      realEstateAsset: {
        id: "",
        rentalUnit: {
          id: "",
        },
      },
    },
    type: RentalAgreementType.BARE,
    startAt: "",
    endAt: "",
    name: "",
    financialConditions: {
      rentCharge: 0,
      rentGuaranteeDeposit: 0,
      amountOfCharges: 0,
      chargePaymentMethod: ChargePaymentMethodEnum.ProvisionOnCharge,
      paymentTerms: PaymentTermsEnum.Monthly,
      rentPayedFor: RentPayedForEnum.UpcomingMonth,
      amountOfTVA: 0,
    },
  };

  const defaultTenant: NewTenant | Tenant = {
    productId: "",
    realEstateAssetId: "",
    rentalAgreementId: "",
    lastName: "",
    firstName: "",
    email: "",
    phone: "",
    type: TenantTypeEnum.NATURAL_PERSON,
    denomination: "",
    numSIREN: "",
    numTVA: "",
  };

  const defaultRepresentative = {
    lastName: "",
    firstName: "",
    email: "",
    phone: "",
    address: {
      street: "",
      zip: "",
      city: "",
      additional: "",
    },
  };

  const defaultFinancialConditions: FinancialConditions = {
    rentCharge: 0,
    rentGuaranteeDeposit: 0,
    amountOfCharges: 0,
    chargePaymentMethod: ChargePaymentMethodEnum.ProvisionOnCharge,
    paymentTerms: PaymentTermsEnum.Monthly,
    rentPayedFor: RentPayedForEnum.UpcomingMonth,
    amountOfTVA: 0,
  };

  const rentalAgreement = ref<RentalAgreement | NewRentalAgreement>({
    ...defaultRentalAgreement,
  });
  const tenants: Ref<Tenant[] | NewTenant[]> = ref([{ ...defaultTenant }]);

  function addTenant(tenants) {
    tenants.push({ ...defaultTenant });
  }

  const tenantType = [
    { value: TenantTypeEnum.NATURAL_PERSON, text: "Particulier" },
    { value: TenantTypeEnum.COMPANY, text: "Société" },
    { value: TenantTypeEnum.ASSOCIATION, text: "Association" },
  ];

  const typeRentalAgreementItems = [
    { value: RentalAgreementType.BARE, text: "Bail location nue" },
    { value: RentalAgreementType.FURNISHED, text: "Bail location meublée" },
  ];

  const tenseArea = ref<TenseArea | undefined>();

  const initializeTenseArea = (reset?: boolean) => {
    if (reset) {
      tenseArea.value = undefined;
      return;
    }
    tenseArea.value = {
      monthlyRentReference: 0,
      monthlyRentReferenceIncreased: 0,
      supplementRent: 0,
      supplementRentJustification: "",
    };
  };

  const amountOfTVA = ref<number | undefined>();

  const paymentChargeMethodItems = [
    {
      value: ChargePaymentMethodEnum.ProvisionOnCharge,
      text: "Provisions sur charges",
    },
    {
      value: ChargePaymentMethodEnum.PeriodicallyWithoutRegulation,
      text: "Périodiquement sans régularisation",
    },
    {
      value: ChargePaymentMethodEnum.AbsenceOfCharge,
      text: "Absence de charges",
    },
    {
      value: ChargePaymentMethodEnum.FixedAmount,
      text: "Au forfait",
    },
  ];

  const paymentTermsItems = [
    { value: PaymentTermsEnum.Monthly, text: "Mensuelle" },
    { value: PaymentTermsEnum.Quarterly, text: "Trimestrielle" },
    { value: PaymentTermsEnum.HalfYearly, text: "Semestrielle" },
  ];

  const rentPayedForItems = [
    {
      value: RentPayedForEnum.UpcomingMonth,
      text: "Le mois à venir (paiement à échoir)",
    },
    {
      value: RentPayedForEnum.PastMonth,
      text: "Le mois écoulé (paiement à terme échu)",
    },
    { value: RentPayedForEnum.Other, text: "Autre" },
  ];

  const rentTypeItems = [
    { value: RentIndexationTypeParamEnum.IRL_metro, text: "IRL Métropole" },
    { value: RentIndexationTypeParamEnum.IRL_corse, text: "IRL Corse" },
    { value: RentIndexationTypeParamEnum.IRL_outre, text: "IRL Outre-Mer" },
  ];

  const quarterlyPeriodItems = [
    { value: QuarterPeriodEnum.T1, text: "T1" },
    { value: QuarterPeriodEnum.T2, text: "T2" },
    { value: QuarterPeriodEnum.T3, text: "T3" },
    { value: QuarterPeriodEnum.T4, text: "T4" },
  ];

  const { validEmailRule } = useEmail();

  const rentalAgreementRules = computed(() => {
    return {
      name: (rentalAgreement: RentalAgreement) =>
        !!rentalAgreement.name ||
        "Renseignez un nom pour ce contrat de location",
      startAt: (rentalAgreement: RentalAgreement) =>
        !!rentalAgreement.startAt || "Ce champ est obligatoire",
      type: (rentalAgreement: RentalAgreement) =>
        !!rentalAgreement.type || "Renseignez le type de contrat de location",
      tenantType: (tenant: Tenant) =>
        !!tenant.type || "Ce champ est obligatoire",
      tenantfirstName: (tenant: Tenant) =>
        !!tenant.firstName || "Ce champ est obligatoire",
      tenantlastName: (tenant: Tenant) =>
        !!tenant.lastName || "Ce champ est obligatoire",
      tenantDenomination: (tenant: Tenant) =>
        !!tenant.denomination || "Ce champ est obligatoire",
      tenantNumSIREN: (tenant: Tenant) =>
        (!!tenant.numSIREN &&
          onlyNumber(tenant.numSIREN) &&
          tenant.numSIREN.length === 14) ||
        "Le format est incorrect",
      tenantEmail: (tenant: Tenant) =>
        (!!tenant.email && validEmailRule(tenant.email)) ||
        "Le format est incorrect",

      representativeFirstName: (tenant: Tenant) =>
        !!tenant.representative?.firstName || "Ce champ est obligatoire",
      representativeLastName: (tenant: Tenant) =>
        !!tenant.representative?.lastName || "Ce champ est obligatoire",
      representativeStreet: (tenant: Tenant) =>
        !!tenant.representative?.address?.street || "Ce champ est obligatoire",
      representativeZip: (tenant: Tenant) =>
        !!tenant.representative?.address?.zip || "Ce champ est obligatoire",
      representativeCity: (tenant: Tenant) =>
        !!tenant.representative?.address?.city || "Ce champ est obligatoire",
    };
  });

  return {
    tenantType,
    defaultRentalAgreement,
    rentalAgreement,
    tenants,
    typeRentalAgreementItems,
    initializeTenseArea,
    tenseArea,
    defaultFinancialConditions,
    amountOfTVA,
    paymentChargeMethodItems,
    paymentTermsItems,
    rentPayedForItems,
    rentTypeItems,
    quarterlyPeriodItems,
    addTenant,
    defaultRepresentative,
    defaultTenant,
    rentalAgreementRules,
  };
}
