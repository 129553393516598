import { ProductsModel, RequireField, SubscriptionsModel } from "@edmp/api";

export const SubscriptionEventType = "SubscriptionEvent" as const;

export type SubscriptionEventData = Pick<
  SubscriptionsModel.Subscription,
  "id" | "productId" | "plan" | "lastInvoice"
> & {
  productEmail: ProductsModel.Product["email"];
  status:
    | SubscriptionsModel.PaymentStatusParams
    | SubscriptionsModel.PaymentStatus;
  lastInvoice?: Pick<
    RequireField<SubscriptionsModel.Subscription, "lastInvoice">["lastInvoice"],
    "amount" | "amountTax" | "amountDiscount" | "amountTotal" | "currency"
  >;
};

export type SubscriptionEvent = CustomEvent<SubscriptionEventData>;

// Methode pour créer un SubscriptionEvent (car l'héritage et le super ne fonctionne pas en es5)
export function dispatchSubscriptionEvent(data: SubscriptionEventData) {
  dispatchEvent(
    new CustomEvent<SubscriptionEventData>(SubscriptionEventType, {
      detail: data,
    })
  );
}
