var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"generic-table"}},[[_c('v-card',{staticClass:"px-3",attrs:{"outlined":""}},[_c('v-data-table',{staticClass:"mt-4 mb-1 table",class:{ hideGroupByCloseButton: _vm.hideGroupByCloseButton },attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.itemsPerPage,"group-by":_vm.groupBy,"hide-default-header":_vm.hideHeader,"hide-default-footer":_vm.hideFooter,"disable-sort":_vm.disableSort,"item-class":_vm.highlightLastRow ? _vm.highlightRow : null},scopedSlots:_vm._u([_vm._l((_vm.headers),function(ref){
var headerValue = ref.value;
return {key:("header." + headerValue),fn:function(ref){
var header = ref.header;
return [_c('v-row',{key:headerValue,staticClass:"gap-5"},[_c('div',{staticClass:"no-whitespace"},[_vm._v(" "+_vm._s(header.text)+" ")]),(header.info)?_c('v-tooltip',{attrs:{"color":"primary","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(header.info)}})]):_vm._e()],1)]}}}),_vm._l((_vm.headers),function(ref){
var headerValue = ref.value;
return {key:("item." + headerValue),fn:function(ref){
var value = ref.value;
var item = ref.item;
var index = ref.index;
return [_vm._t(("item." + headerValue),null,{"headerValue":headerValue,"item":item,"value":value,"rowIndex":index})]}}}),(_vm.noDataTextHtml)?{key:"no-data",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.noDataTextHtml)}})]},proxy:true}:null,(_vm.isEnableCustomFooter)?{key:"footer",fn:function(ref){
var props = ref.props;
var on = ref.on;
var headers = ref.headers;
var widths = ref.widths;
return [_c('div',{staticClass:"footer"},[_vm._t("footer",null,{"props":props,"on":on,"headers":headers,"widths":widths})],2)]}}:null],null,true)}),_c('div',{staticClass:"mt-1"},[_vm._t("default")],2)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }