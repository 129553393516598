import { SubscriptionsModel } from "@edmp/api";

export const ModelsLibraryEventType = "ModelsLibraryEvent" as const;

export enum ModelsLibraryEventCode {
  ENTER_MODELS_LIBRARY = "enterModelsLibrary",
  QUIT_MODELS_LIBRARY = "quitModelsLibrary",
  CLICK_CARD_MODELS_LIBRARY = "click-cardModelsLibrary",
  CLICK_REFUSED_CARD_MODELS_LIBRARY = "click-refusedCardModelsLibrary",
  CLICK_DOWNLOAD_MODEL_DOCX = "click-downloadModelDocx",
  CLICK_DOWNLOAD_MODEL_PDF = "click-downloadModelPdf",
}

export type ModelsLibraryEventData<
  Code extends ModelsLibraryEventCode = ModelsLibraryEventCode
> = {
  code: Code;
  userId: string;
  productId: string;
  subscriptionPlan: SubscriptionsModel.Subscription["plan"]["type"] | "unknown";
  date: string;
} & (
  | { code: ModelsLibraryEventCode.ENTER_MODELS_LIBRARY }
  | { code: ModelsLibraryEventCode.QUIT_MODELS_LIBRARY }
  | { code: ModelsLibraryEventCode.CLICK_CARD_MODELS_LIBRARY }
  | { code: ModelsLibraryEventCode.CLICK_REFUSED_CARD_MODELS_LIBRARY }
  | { code: ModelsLibraryEventCode.CLICK_DOWNLOAD_MODEL_DOCX }
  | { code: ModelsLibraryEventCode.CLICK_DOWNLOAD_MODEL_PDF }
) & { modelType?: string };

export type ModelsLibraryEvent = CustomEvent<ModelsLibraryEventData>;

export function dispatchModelsLibraryEvent(data: ModelsLibraryEventData) {
  dispatchEvent(
    new CustomEvent<ModelsLibraryEventData>(ModelsLibraryEventType, {
      detail: data,
    })
  );
}
