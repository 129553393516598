































































































































import SCISearch from "@/components/sign/signup/steps/SCISearch.vue";
import { defineComponent, PropType, Ref, ref } from "@vue/composition-api";
import { ProductsModel, TaxRegime } from "@edmp/api";
import { dispatchOnBoardingEvent, OnBoardingEventSteps } from "@/events";
import { usersStore } from "@/store";

export default defineComponent({
  name: "RegisterActivity",
  components: { SCISearch },
  props: {
    step: {
      type: String,
      required: true,
    },
    taxRegime: {
      type: String as PropType<TaxRegime>,
      required: false,
    },
    type: {
      type: String as PropType<ProductsModel.Tags>,
      require: false,
    },
  },
  setup(props, context) {
    const activityRegistered: Ref<boolean | undefined> = ref(undefined);
    const validateInProgress: Ref<boolean> = ref(false);

    const user = usersStore.loggedInUser;

    const validate = async () => {
      validateInProgress.value = true;
      if (props.taxRegime) {
        context.emit(
          activityRegistered.value ? "validate" : "validateNonRegistered"
        );
        dispatchOnBoardingEvent({
          step: OnBoardingEventSteps.ACTIVITY,
          userId: user?.id || "",
          productType: (() => {
            switch (props.taxRegime) {
              case TaxRegime.IR_2072:
                return ProductsModel.ProductTypes.SCI;
              case TaxRegime.IS_2065:
                return ProductsModel.ProductTypes.SCI;
              case TaxRegime.LMNP_2031:
                return ProductsModel.ProductTypes.LMNP;
              default:
                return "";
            }
          })(),
          taxRegime: (() => {
            switch (props.taxRegime) {
              case TaxRegime.IR_2072:
                return "IR";
              case TaxRegime.IS_2065:
                return "IS";
              case TaxRegime.LMNP_2031:
                return "LMNP";
              default:
                return "";
            }
          })(),
          activityRegistered: !!activityRegistered.value,
        });
      }
    };
    return { TaxRegime, activityRegistered, validateInProgress, validate };
  },
});
