import { bankAccountsService, banksService } from "@/services";
import { Ref, ref } from "@vue/composition-api";
import { BankAccount, SpecProvider } from "@edmp/api";
import router from "@/router";
import { isAfter, parse, format, parseISO } from "date-fns";
import { OnBoardingEventSteps, dispatchOnBoardingEvent } from "@/events";
import { productsStore, usersStore } from "@/store";

/**
 * Used in RegisterBankAccount (/connect webview), ManageBankAccount (/manage), ReconnectBankAccount (/reconnect)
 *
 * @param productId Product Id used to add bank account
 * @param modal Modal mode
 */
export function useBudgetInsight(productId: string, modal: boolean) {
  const requestForAccompanied = ref(false);
  const bankAccounts: Ref<BankAccount[]> = ref([]);
  const changeAccounts: Ref<boolean> = ref(false);
  let detectSyncAccountRetry = false;

  async function finishSynchronization() {
    detectSyncAccountRetry = false;
    changeAccounts.value = true;
  }

  async function accountsHasBeenChanged(
    newBankAccounts: BankAccount[]
  ): Promise<void> {
    let changeDetected = false;

    for (const newBankAccount of newBankAccounts) {
      // Search if account exist in previous sync
      const bankAccount = bankAccounts.value.find(
        (bankAccount) => bankAccount.id === newBankAccount.id
      );

      changeDetected =
        changeDetected || // Break next loop if OK
        !bankAccount || // If undefined => New BankAccount detected
        isAfter(
          parse(
            format(parseISO(newBankAccount.lastSync), "dd/MM/yyyy HH:mm:ss"),
            "dd/MM/yyyy HH:mm:ss",
            new Date()
          ),
          parse(
            format(parseISO(bankAccount.lastSync), "dd/MM/yyyy HH:mm:ss"),
            "dd/MM/yyyy HH:mm:ss",
            new Date()
          )
        );
    }

    if (changeDetected) {
      await finishSynchronization();
    }
  }

  // Second timer to detect new accounts of change on existing account
  async function detectSynchro() {
    const syncBankAccounts = (
      await bankAccountsService.list({ productId: productsStore.currentId })
    ).filter((b) => b.name !== "Manuel");
    await accountsHasBeenChanged(syncBankAccounts);
    if (detectSyncAccountRetry) {
      setTimeout(detectSynchro, 3000);
    }
  }

  // First timer to waiting new accounts
  async function synchronizeBankAccount(): Promise<void> {
    const syncBankAccounts = (
      await bankAccountsService.list({ productId: productsStore.currentId })
    ).filter((b) => b.bankId !== "Manual");
    if (syncBankAccounts.length > 0) {
      // Check if it is the first bank account
      if (
        bankAccounts.value.filter((b) => b.bankId !== "Manual").length === 0
      ) {
        await finishSynchronization();
        // Check if it is on onboarding
        if (!modal) {
          dispatchOnBoardingEvent({
            step: OnBoardingEventSteps.BANK_ACCOUNT,
            userId: usersStore.loggedInUser.id,
            userEmail: usersStore.loggedInUser.email,
            userPhone: usersStore.loggedInUser.phone || "",
            productId,
            isAdded: true,
            requestForAccompanied: requestForAccompanied.value,
          });
          // Redirect to home with product-tour
          // https://stackoverflow.com/questions/62223195/vue-router-uncaught-in-promise-error-redirected-from-login-to-via-a
          router
            .push({ path: "/", query: { welcome: "new" } })
            .catch((error) => console.error(error));
        }
      } else {
        // Async call to launch pooling
        detectSynchro();
      }
    } else {
      setTimeout(synchronizeBankAccount, 3000);
    }
  }

  async function fetchSynchronizeBankAccount(): Promise<void> {
    bankAccounts.value = (
      await bankAccountsService.list({ productId: productsStore.currentId })
    ).filter((b) => b.name !== "Manuel");
  }

  const displayIframe = ref(false);
  const url = ref("");
  let urlLoaded = "";

  async function getUrl(bankId: string, connectProductId = productId) {
    const sync = await banksService.create({
      id: bankId,
      productId: connectProductId,
    });
    url.value = sync.url;
    urlLoaded = sync.url;
    displayIframe.value = true;
  }

  async function getUrlReconnect(bankId: string) {
    const sync = await banksService.reconnect({ bankId });
    url.value = sync.url;
    urlLoaded = sync.url;
    displayIframe.value = true;
  }

  async function getUrlManage(bankId: string, manageProductId = productId) {
    const sync = await banksService.manage({
      bankId,
      productId: manageProductId,
    });
    url.value = sync.url;
    urlLoaded = sync.url;
    displayIframe.value = true;
  }

  async function stopSynchro() {
    detectSyncAccountRetry = false;
  }

  const biIframe = ref();
  const iframeLoadChange = async () => {
    if (!detectSyncAccountRetry) {
      // We start synchro timeout if it is not already starting
      detectSyncAccountRetry = true;
      await synchronizeBankAccount();
    }

    const messageEventHandler = () => {
      if (biIframe?.value?.contentWindow?.location?.href) {
        biIframe.value.contentWindow.location.replace(urlLoaded);
      }
    };
    window.removeEventListener("message", messageEventHandler, false);
    window.addEventListener("message", messageEventHandler, false);
  };

  const providerBI: Ref<SpecProvider | undefined> = ref();
  const providerMocks: Ref<SpecProvider[] | undefined> = ref([]);

  async function fetchAvailableBank() {
    const providers: SpecProvider[] = await banksService.listProviders();
    providerBI.value = providers.find((b) => b.id === "BI");
    // Retrieve mocks
    providers
      .filter((b) => b.id.startsWith("mock"))
      .forEach((b) => providerMocks.value?.push(b));
  }

  return {
    requestForAccompanied,
    url,
    getUrl,
    getUrlReconnect,
    getUrlManage,
    iframeLoadChange,
    displayIframe,
    biIframe,
    bankAccounts,
    synchronizeBankAccount,
    fetchSynchronizeBankAccount,
    fetchAvailableBank,
    providerBI,
    providerMocks,
    changeAccounts,
    stopSynchro,
  };
}
