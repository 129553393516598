































import { defineComponent, ref, PropType } from "@vue/composition-api";
import GenericTable, {
  TableHeaderItem,
} from "@/components/atom/table/GenericTable.vue";
import { RentIndexation } from "@edmp/api";
import { rentalsService } from "@/services";

export default defineComponent({
  name: "IndexationHistory",
  components: { GenericTable },
  props: {
    rentIndexations: {
      type: Array as PropType<RentIndexation[]>,
      required: true,
    },
  },
  setup(props, context) {
    const rentIndexationsValue = ref(props.rentIndexations);

    const historyTableHeaders: TableHeaderItem[] = [
      { text: "Date", value: "revisionDate" },
      { text: "Ancien loyer", value: "currentRent" },
      { text: "Nouveau loyer", value: "newRent" },
      { text: "IRL précédent", value: "currentIrl" },
      { text: "IRL appliqué", value: "newIrl" },
    ];

    const deleteRentIndexation = (id: string) => {
      rentalsService.agreements.deleteIndexation(id).then(() => {
        rentIndexationsValue.value = rentIndexationsValue.value.filter(
          (indexation) => indexation.id !== id
        );
        context.emit("deleteIndexation");
      });
    };

    return { rentIndexationsValue, historyTableHeaders, deleteRentIndexation };
  },
});
