





























































































































































































































































































































































import { computed, defineComponent, PropType, Ref } from "@vue/composition-api";
import { documentsStore } from "@/store";
import { Document } from "@edmp/api";

import { TransactionState } from "./transaction.usable";
import { useCategorization } from "../categorization/categorization.usable";
import { cloneDeep } from "lodash";

export default defineComponent({
  name: "TransactionCategories",
  components: {},
  props: {
    transactionState: {
      type: Object as PropType<TransactionState>,
      required: true,
    },
  },
  setup(props, context) {
    const categorizationUse = useCategorization(
      props.transactionState,
      context
    );

    const computedCategories = computed(() => [
      ...(cloneDeep(props.transactionState.savedCategories) ?? []),
      ...(cloneDeep(props.transactionState.suggestedLines) ?? []),
    ]);

    const computedSuggestionCategories = computed(() =>
      cloneDeep(props.transactionState.suggestedLines)
    );
    const documents: Ref<Document[]> = computed(() => documentsStore.documents);

    const getSupportingDocument = (documentId: string | undefined) => {
      const docId = documentId;
      const document: Document[] = documents.value.filter(
        ({ id }) => id === docId
      );
      return document[0].href;
    };
    return {
      categorizationUse,
      computedCategories,
      computedSuggestionCategories,
      getSupportingDocument,
    };
  },
});
