var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form"},[_c('SectionTitle',{attrs:{"title":"Paramètres d'application"}}),_c('v-alert',{attrs:{"type":"info","text":"","outlined":""}},[_vm._v(" Par défaut, Ownily vous propose l'année calendaire précédente."),_c('br'),_vm._v("Si votre locataire n'a pas occupé le logement sur l'intégralité de cette période ou si vous souhaitez calculer sur une autre période, ajustez les dates ci-dessous. ")]),_c('v-row',{staticClass:"mb-5"},[_c('v-col',{attrs:{"cols":"6"}},[_c('DatePicker',{attrs:{"label":"Début de la période","outlined":"","value":_vm.regularization.periodStart,"required":"","min":_vm.minDate},on:{"input":function (event) { return _vm.$emit('updateRegularization', {
              value: event,
              path: 'periodStart',
            }); }}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('DatePicker',{attrs:{"label":"Fin de la période","outlined":"","value":_vm.regularization.periodEnd,"required":"","max":_vm.maxDate},on:{"input":function (event) { return _vm.$emit('updateRegularization', {
              value: event,
              path: 'periodEnd',
            }); }}})],1)],1),_c('v-row',{staticClass:"mb-1"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"Mode de propriété","outlined":"","readonly":"","value":_vm.regularization.propertyType,"items":_vm.propertyTypeItems,"item-text":"text","item-value":"value","hide-details":"auto","required":""},on:{"input":function (event) { return _vm.$emit('updateRegularization', {
              value: event,
              path: 'propertyType',
            }); }}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"submit-btn"},[_c('v-btn',{attrs:{"outlined":"","depressed":"","color":"primary","type":"button"},on:{"click":_vm.goBack}},[_vm._v(" Retour ")]),_c('v-btn',{staticClass:"white--text",attrs:{"color":"primary","type":"button"},on:{"click":_vm.goNext}},[_vm._v(" Suivant ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }