import store from "@/store/root";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { rentalsService } from "@/services";
import { RegularizationHistoryEntry } from "@edmp/api";
import Vue from "vue";

export interface RegularizationsState {
  regularizations: Array<RegularizationHistoryEntry>;
  loading: boolean;
}

@Module({
  name: "regularizations-store",
  dynamic: true,
  namespaced: true,
  store,
})
export class RegularizationsStore
  extends VuexModule
  implements RegularizationsState
{
  regularizations: Array<RegularizationHistoryEntry> = [];
  loading = false;

  @Mutation
  reset(): void {
    this.regularizations = [];
    this.loading = false;
  }

  @Mutation
  setLoading(isLoading: boolean): void {
    this.loading = isLoading;
  }

  // Regularizations
  @Action
  async fetchRegularizations(
    rentalAgreementId: string
  ): Promise<RegularizationHistoryEntry[]> {
    this.setLoading(true);
    const regularizations = await rentalsService.agreements.listRegularizations(
      {
        rentalAgreementId,
      }
    );
    this.setRegularizations(regularizations);
    this.setLoading(false);
    return regularizations;
  }

  get getRegularizationsByRentalAgreementId() {
    return (rentalAgreementId: string) => {
      return this.regularizations.filter(
        (regularization) =>
          regularization.rentalAgreementId === rentalAgreementId
      );
    };
  }

  // Regularization
  @Mutation
  setRegularization(regularization: RegularizationHistoryEntry): void {
    const index = this.regularizations.findIndex(
      ({ id }) => id == regularization.id
    );
    if (index !== -1) {
      Vue.set(this.regularizations, index, regularization);
    } else {
      this.regularizations.push(regularization);
    }
  }

  @Mutation
  setRegularizations(regularizations: RegularizationHistoryEntry[]): void {
    this.regularizations = regularizations;
  }

  @Mutation
  removeRegularization(regularizationId: string): void {
    rentalsService.agreements.deleteRegularization(regularizationId);
    const index = this.regularizations.findIndex(
      ({ id }) => id == regularizationId
    );
    if (index !== -1) {
      this.regularizations.splice(index, 1);
    }
  }

  // Create
  @Action
  async createRegularization(
    regularizationCreate: RegularizationHistoryEntry
  ): Promise<RegularizationHistoryEntry> {
    this.setLoading(true);
    if (!regularizationCreate.rentalAgreementId) {
      throw new Error(
        "Cannot find rental agreement id in `regularization.rentalAgreementId`"
      );
    }
    const newRegularization =
      await rentalsService.agreements.createRegularization(
        regularizationCreate
      );
    this.setRegularization(newRegularization);
    this.setLoading(false);
    return newRegularization;
  }

  @Action
  async deleteRegularization(regularizationId: string): Promise<void> {
    this.setLoading(true);
    await rentalsService.agreements.deleteRegularization(regularizationId);
    this.removeRegularization(regularizationId);
    this.setLoading(false);
  }
}
