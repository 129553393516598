






















































import { TaskGroupLocal } from "@/models";
import { ROUTE_NAMES } from "@/router/routes";
import {
  accountingPeriodsStore,
  productsStore,
  subscriptionsStore,
  tasksStore,
} from "@/store";
import {
  getReferredIdByTypeWithReferences,
  SubscriptionsModel,
  TaskTypeReference,
  TaskCode,
  TaskGroupCode,
} from "@edmp/api";
import { defineComponent, PropType } from "@vue/composition-api";

export default defineComponent({
  name: "TaskGroupAction",
  props: {
    taskGroupLocal: {
      type: Object as PropType<TaskGroupLocal>,
      required: true,
    },
  },
  setup() {
    const validateEventGeneralAssemblyYearEndCreateStep = async () => {
      const taskCreate = tasksStore.taskGroups
        .find(
          (taskGroup) =>
            taskGroup.groupCode === TaskGroupCode.EventGeneralAssemblyYearEnd &&
            getReferredIdByTypeWithReferences(
              taskGroup.references,
              TaskTypeReference.product
            ) === productsStore.currentId &&
            getReferredIdByTypeWithReferences(
              taskGroup.references,
              TaskTypeReference.accountingPeriod
            ) === accountingPeriodsStore.currentId
        )
        ?.tasksLocal.find((task) => {
          return task.code === TaskCode.Create;
        });
      if (taskCreate) {
        await tasksStore.validateTask({
          taskLocal: taskCreate,
        });
      }
    };

    return {
      validateEventGeneralAssemblyYearEndCreateStep,
      TaskGroupCode,
      ROUTE_NAMES,
      isIR: accountingPeriodsStore.isIR,
      isPremium:
        subscriptionsStore.getCurrentSubscription?.plan.type ===
        SubscriptionsModel.PlanType.Premium,
    };
  },
});
