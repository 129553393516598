import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueCompositionApi from "@vue/composition-api";
import initAxios from "./plugins/axios";
import i18n from "./plugins/i18n";
import store from "@/store/root";
import VueTour from "vue-tour";
import initCrisp from "./plugins/crisp";
import SequentialEntrance from "vue-sequential-entrance";
import "vue-sequential-entrance/vue-sequential-entrance.css";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import { abilitiesPlugin } from "@casl/vue";
import { Can } from "@casl/vue";
import { ability } from "@/services/Permissions.service";
import { coreStore } from "./store";
import initGTM from "./plugins/gtm";
import { Env } from "./store/modules/Core.store";
import VueSignaturePad from "vue-signature-pad/dist/vue-signature-pad.esm";

Vue.config.productionTip = true;

// Init service plugin
(async function () {
  const { env, services } = await coreStore.getConfig();

  await initAxios();

  setTimeout(
    async () => {
      if (services.gtm) {
        initGTM();
      }

      if (services.crisp) {
        initCrisp();
      }
    },
    env === Env.production || env === Env.staging ? 0 : 600000 // 10 min
  );
})();

Vue.use(abilitiesPlugin, ability);
Vue.component("Can", Can);

Vue.use(VueCompositionApi);
Vue.use(VueSignaturePad);

Vue.prototype.$stripeCheckout = StripeCheckout;

require("./assets/vue-tour.css");

/* @ts-ignore: To be checked, could be a version problem */
Vue.use(VueTour);

Vue.use(SequentialEntrance);

new Vue({
  router,
  vuetify,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
