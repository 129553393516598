import { render, staticRenderFns } from "./TaskGroupCard.vue?vue&type=template&id=8db6c648&scoped=true&"
import script from "./TaskGroupCard.vue?vue&type=script&lang=ts&"
export * from "./TaskGroupCard.vue?vue&type=script&lang=ts&"
import style0 from "./TaskGroupCard.vue?vue&type=style&index=0&id=8db6c648&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8db6c648",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard})
