









































































































































































































































import {
  defineComponent,
  computed,
  ref,
  watch,
  onBeforeMount,
} from "@vue/composition-api";
import {
  round2decimals,
  getMonthlyPayment,
  getLoanTypeText,
  RealEstateLoan,
} from "@edmp/api";
import { realEstatesService } from "@/services";
import { productsStore, realEstateLoansStore } from "@/store";
import LoansChart from "../../charts/LoansChart.vue";
import { ROUTE_NAMES } from "@/router/routes";

import moment from "moment";

export default defineComponent({
  name: "RealEstateLoans",
  components: { LoansChart },
  props: {
    realEstateAssetId: { type: String, required: false },
    productId: { type: String },
  },
  setup(props) {
    const isLoading = ref(false);

    const realEstateLoansList = computed(() => {
      if (props.realEstateAssetId) {
        return realEstateLoansStore.getRealEstateLoansByRealEstateAssetId(
          props.realEstateAssetId
        );
      } else if (props.productId) {
        return realEstateLoansStore.getRealEstateLoansByProductId(
          props.productId
        );
      } else {
        return [];
      }
    });

    const isNew = computed(() => realEstateLoansList.value?.length === 0);

    const totalPayment = (realEstateLoan: RealEstateLoan): number =>
      round2decimals(getMonthlyPayment(realEstateLoan));
    const downloadPdf = (realEstateLoanId: string) =>
      realEstatesService.loans.getAmortisationSchedule({
        id: realEstateLoanId,
      });

    // Init
    async function init() {
      isLoading.value = true;
      if (productsStore.currentId) {
        await productsStore.switchProduct({ id: productsStore.currentId });
      }
      await realEstateLoansStore.fetchRealEstateLoans(productsStore.currentId);
      isLoading.value = false;
    }

    watch(
      () => productsStore.currentProduct,
      () => init(),
      { deep: true }
    );

    onBeforeMount(() => init());

    return {
      realEstateLoansList,
      isNew,
      downloadPdf,
      moment,
      totalPayment,
      getLoanTypeText,
      isLoading,
      ROUTE_NAMES,
    };
  },
});
