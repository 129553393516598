import { Document, model, Schema } from "mongoose";
import { ulid } from "ulid";
import {
  AccountingBalanceSheetReporting,
  BankAccount,
  Direction,
  JournalEntryReference,
  Partner,
  RealEstateAsset,
  RealEstateLoan,
  ReferenceCounter,
  RentalAgreement,
  Suggestions,
  Tenant,
  TransactionType,
} from "..";
import { Address, addressSchema, OneOf } from "./Common.model";
import { LedgerAccountName, LedgerAccountNumber } from "./JournalComposedEntry";
export interface AccountingComputeMonth {
  month: string;
  startAt: string;
  endAt: string;
  transactionsDebits: number;
  transactionsCredits: number;
  transactionsBalance: number;
  transactionsCurrency: string;
}
export interface AccountingComputeCategories {
  name?: string;
  direction?: string;
  monthly?: {
    [key: string]: number;
  };
}
export interface AccountingCompute {
  period: string;
  startAt: string;
  endAt: string;
  transactionsDebits: number;
  transactionsCredits: number;
  transactionsCurrency: string;
  transactionsBalance: number;
  monthly: {
    [key: string]: AccountingComputeMonth;
  };
  categories: AccountingComputeCategories[];
}
export interface AccountingPeriodCompute {
  [key: string]: AccountingCompute;
}
export enum AccountingType {
  SIMPLE = "simple", // simple report
  COMPLETE = "complete", // Fec custom
  STANDARD = "standard", // Fec normé
  BALANCE_SHEET = "balance-sheet",
}
type BalanceCategoryCommon = {
  types: TransactionType[];
  debit: number;
  credit: number;
  balance: number;
  balanceDirection: Direction;
  transactionIds: string[];
};
export type BalanceCategory = BalanceCategoryCommon & {
  account: string;
  reference?: ReferenceCounter;
  yearly: (BalanceCategoryCommon & {
    yearDate: string;
    monthly: (BalanceCategoryCommon & {
      monthDate: string;
      daily: (BalanceCategoryCommon & {
        dayDate: string;
        categorizations: {
          type: TransactionType;
          account: string;
          date: string;
          amount: number;
          direction: Direction;
          transactionId?: string;
          refs?: JournalEntryReference[];
        }[];
      })[];
    })[];
  })[];
};
export type BalanceCategories = BalanceCategoryCommon & {
  categories: BalanceCategory[];
};

/**
 * * Accounting reporting
 */
/**
 * This interface is used to add common data for accounting and to archive it in BDD
 */
export interface AccountingCommon {
  id: string;
  userId: string;
  productId: string;
  companyName: string;
  companyAddress: Address;
  yearPeriod: string;
  startAt: string;
  endAt: string;
  userLastName: string;
  userFirstName: string;
}
/**
 * This interface is used to edit a PDF table for accounting and to archive it in BDD
 */
export interface AccountingSimple extends AccountingCommon {
  type: AccountingType.SIMPLE;
  data: {
    totalIncome: string;
    totalOutcome: string;
    totalBalance: string;
    months: Array<string>;
    monthsCredits: Array<string>;
    monthsDebits: Array<string>;
    monthsBalance: Array<string>;
    monthsCatCredits: Array<Array<string>>;
    monthsCatDebits: Array<Array<string>>;
  };
}
/**
 * This interface is used to edit a FEC xlsx file for accounting and to archive it in BDD
 */
export interface AccountingComplete extends AccountingCommon {
  type: AccountingType.COMPLETE;
  data: {
    operations: OperationLine[];
  };
}
/**
 * This interface is used to edit a FEC standard txt file for accounting and to archive it in BDD
 */
export interface AccountingStandard extends AccountingCommon {
  type: AccountingType.STANDARD;
  data: {
    operations: OperationLine[];
  };
}

export type AccountingReporting =
  | AccountingSimple
  | AccountingComplete
  | AccountingStandard
  | AccountingBalanceSheetReporting;

export type OperationLine = {
  date: string;
  summary: string;
  documentWording?: string;
  documentId?: string;
  documentDate?: string;
  accountName: LedgerAccountName;
  account: LedgerAccountNumber;
  debit: number | undefined;
  credit: number | undefined;
  lettering: string;
  order: number;
  reference?: ReferenceCounter | JournalEntryReference;
  realEstateAsset?: Pick<RealEstateAsset, "id" | "name">;
  partner?: Pick<Partner, "id" | "lastName" | "firstName" | "denomination" | "type">;
  rentalAgreement?: Pick<RentalAgreement, "id" | "name">;
  tenant?: Pick<Tenant, "id"> & OneOf<Pick<Tenant, "firstName" | "lastName">, Pick<Tenant, "denomination">>;
  realEstateLoan?: Pick<RealEstateLoan, "id" | "name">;
  bankAccount?: Pick<BankAccount, "id" | "name">;
  beneficiary?: { id: string; name: string };
};

const accountingSchema = new Schema<AccountingDocument>(
  {
    _id: { type: String, default: () => ulid() },
    type: { type: String, enum: Object.values(AccountingType), index: true },
    userId: { type: String, index: true },
    productId: { type: String, index: true },
    companyName: { type: String },
    companyAddress: addressSchema,
    yearPeriod: { type: String },
    startAt: { type: String },
    endAt: { type: String },
    userLastName: { type: String },
    userFirstName: { type: String },
    data: { type: Object },
  },
  {
    timestamps: true,
    toJSON: {
      versionKey: false,
      virtuals: true,
      transform(doc, ret: AccountingDocument) {
        ret.id = ret._id;
        delete ret._id;
        return ret;
      },
    },
  }
);

export type AccountingDocument = AccountingReporting & Document<string>;

// Name of the collection in third argument
export const AccountingModel = model<AccountingDocument>("Accounting", accountingSchema, "Accountings");

// API
export namespace AccountingsService {
  export type GetBalanceIn = { productId: string };
  export type GetBalanceOut = AccountingPeriodCompute;

  export type GetBalanceCategoriesIn = { accountingPeriodId: string };
  export type GetBalanceCategoriesOut = BalanceCategories;

  export type ListOperationsIn = { accountingPeriodId: string };
  export type ListOperationsOut = OperationLine[];

  export type ListCategoriesIn = { accountingPeriodId: string };
  export type ListCategoriesOut = Suggestions;

  export type GetReportingSimpleIn = { accountingPeriodId: string };
  export type GetReportingSimpleOut = Buffer;

  export type GetReportingCompleteIn = { accountingPeriodId: string };
  export type GetReportingCompleteOut = Buffer;

  export type GetReportingFecIn = {
    type: AccountingType.COMPLETE | AccountingType.STANDARD;
    accountingPeriodId: string;
  };
  export type GetReportingFecOut = Buffer;
}
