import { OneOf, ProductsModel, User } from "@edmp/api";

export const OnBoardingEventType = "OnBoardingEvent" as const;

export enum OnBoardingEventSteps {
  ACTIVITY = "activity",
  USER_DETAILS = "userDetails",
  ACTIVITY_DETAILS = "activityDetails",
  BANK_ACCOUNT = "bankAccount",
}

export type OnBoardingEventData<
  OnBoardingEventStep extends OnBoardingEventSteps = OnBoardingEventSteps
> = {
  step: OnBoardingEventStep;
  userId: string;
} & (OnBoardingEventStep extends OnBoardingEventSteps.ACTIVITY
  ? {
      step: OnBoardingEventSteps.ACTIVITY;
      productType: ProductsModel.ProductTypes | "";
      taxRegime: "IR" | "IS" | "LMNP" | "";
      activityRegistered: boolean;
    }
  : OnBoardingEventStep extends OnBoardingEventSteps.USER_DETAILS
  ? {
      step: OnBoardingEventSteps.USER_DETAILS;
      userEmail: User["email"];
      userPhone: User["phone"];
      productType: ProductsModel.ProductTypes | "";
      taxRegime: "IR" | "IS" | "LMNP" | "";
      activityRegistered: boolean;
    }
  : OnBoardingEventStep extends OnBoardingEventSteps.ACTIVITY_DETAILS
  ? {
      step: OnBoardingEventSteps.ACTIVITY_DETAILS;
      userEmail: User["email"];
      userPhone: User["phone"];
      productId: string;
      productType: ProductsModel.ProductTypes;
      taxRegime: "IR" | "IS" | "LMNP";
    } & (
      | {
          taxRegime: "LMNP";
          declarationType: "MICRO" | "REAL_SIMPLIFIED" | "UNKNOWN";
        }
      | {
          taxRegime: "IR" | "IS" | "LMNP";
        }
    )
  : OnBoardingEventStep extends OnBoardingEventSteps.BANK_ACCOUNT
  ? {
      step: OnBoardingEventSteps.BANK_ACCOUNT;
      userEmail: User["email"];
      userPhone: User["phone"];
      productId: string;
      requestForAccompanied: boolean;
    } & OneOf<{ isCanceled: boolean }, { isAdded: boolean }>
  : never);

export type OnBoardingEvent = CustomEvent<OnBoardingEventData>;

export function dispatchOnBoardingEvent(data: OnBoardingEventData) {
  dispatchEvent(
    new CustomEvent<OnBoardingEventData>(OnBoardingEventType, { detail: data })
  );
}
