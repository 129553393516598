import { uniqBy } from "lodash";
import {
  AnomaliesChecked,
  anomaliesSortByPriority,
  AnomalyCode,
  AnomalyErrorResult,
  AnomalyOptions,
  BankAccount,
  Document,
  isPlanBankAccount,
  JournalComposedEntry,
  JournalEntryLine,
  Partner,
  RealEstateAsset,
  RealEstateLoan,
  RentalAgreement,
  RentalUnit,
  Suggestion,
  TaxRegime,
  Tenant,
} from "..";
import { anomaliesHelpers } from "./anomaliesHelper.lib";
import { checkLoanAnomalies } from "./anomaliesLoan.lib";
import { checkTaxTvaAnomalies } from "./anomaliesTaxTva.lib";
import { checkTypeReferenceAnomalies } from "./anomaliesTypeReference.lib";
import { checkObjectIdLinkAnomalies } from "./anomaliesObjectIdLink.lib";
import { checkAssetAnomalies } from "./anomaliesAsset.lib";

export enum CheckNewAnomaliesType {
  transaction = "transaction",
  functional = "functional",
}

export type CheckNewAnomaliesSubParams = {
  [CheckNewAnomaliesType.transaction]: {
    operations: JournalComposedEntry[];
    categories: Suggestion[];
  };
  // eslint-disable-next-line @typescript-eslint/ban-types
  [CheckNewAnomaliesType.functional]: {};
};

export type CheckNewAnomaliesParams<Type extends CheckNewAnomaliesType> = {
  checkNewAnomaliesType?: Type;
  params: CheckNewAnomaliesSubParams[Type];
  data: {
    bankAccounts: BankAccount[];
    taxRegime?: TaxRegime;
    realEstateAssets: RealEstateAsset[];
    rentalUnits: RentalUnit[];
    rentalAgreements: RentalAgreement[];
    tenants: Tenant[];
    partners: Partner[];
    realEstateLoans: RealEstateLoan[];
    documents: Document[];
  };
  options?: AnomalyOptions;
};

/**
 * Checking if the anomalyParams are present and if they are, it checks if the referenceId is present.
 */
const validateOptions = ({ options }: Pick<CheckNewAnomaliesParams<CheckNewAnomaliesType>, "options">) => {
  if (
    options &&
    Object.values(options).length &&
    Object.values(options).filter((anomalyCode) => anomalyCode.params && Object.values(anomalyCode.params).length)
      .length
  ) {
    const referenceIdAnomaliesRequired = Object.entries(options)
      .filter(([, anomalyValue]) => !anomalyValue.referenceId)
      .map(([anomalyCode]) => anomalyCode as AnomalyCode);

    if (referenceIdAnomaliesRequired.length) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Option anomaly params require referenceId for anomalies code: ${referenceIdAnomaliesRequired} `);
    }
  }
};

/**
 * It's filtering the transactions to only keep the ones that have a referenceId in their lines.
 */
const filteredTransactionByReferenceId = ({
  params,
  options,
}: Pick<CheckNewAnomaliesParams<CheckNewAnomaliesType.transaction>, "params" | "options">) => {
  if (!options) return params.operations;

  const filteredOperations: JournalComposedEntry[] = [];
  for (const anomalyValue of Object.values(options)) {
    if (anomalyValue.referenceId) {
      filteredOperations.push(
        ...params.operations.filter(
          (operation) =>
            operation?.journalEntry.lines?.filter(
              (line) =>
                line.refs && line.refs?.map((ref) => ref.referredId).includes(anomalyValue.referenceId as string)
            ).length !== 0
        )
      );
    } else {
      filteredOperations.push(...params.operations);
    }
  }
  return uniqBy(filteredOperations, "id");
};

/**
 * "It takes a list of journal entries, and returns a list of journal entries with anomalies."
 *
 * The function is broken down into three parts:
 *
 * 1. Validator
 * 2. if no checkNewAnomaliesType or checkNewAnomaliesType transaction
 *    1. Filtered transaction
 *    2. Process anomalies
 *    3. Formate anomalies result
 * 3. if no checkNewAnomaliesType or checkNewAnomaliesType functional
 *    1. Process anomalies
 *
 * The validator checks if the anomalyParams are present and if they are, it checks if the referenceId is present
 * @returns An object with the following properties:
 * - anomaliesErrors: An array of AnomalyError.
 * - operationsErrors: An array of JournalComposedEntry.
 * - anomaliesResolved: An array of AnomalyError.
 * - operationsResolved: An array of JournalComposedEntry.
 * - operationsWithErrorsAndResolved: An array of JournalComposedEntry.
 */
export const checkNewAnomalies = <Type extends CheckNewAnomaliesType>({
  checkNewAnomaliesType,
  params,
  data,
  options,
}: CheckNewAnomaliesParams<Type>): AnomaliesChecked => {
  /**
   * * Validator
   * Checking if the anomalyParams are present and if they are, it checks if the referenceId is present.
   */
  validateOptions({ options });

  /**
   * * Process anomalies
   */
  const { hasNoOptionOrOptionAnomalyCode } = anomaliesHelpers({
    data,
    options,
  });
  const anomaliesErrors: AnomalyErrorResult[] = [];
  const operationsErrors: JournalComposedEntry[] = [];
  const anomaliesResolved: AnomalyErrorResult[] = [];
  const operationsResolved: JournalComposedEntry[] = [];
  const operationsWithErrorsAndResolved: JournalComposedEntry[] = [];

  /**
   * * Process anomalies transactions
   */
  if (!checkNewAnomaliesType || checkNewAnomaliesType === CheckNewAnomaliesType.transaction) {
    /**
     * * Filtered transaction
     * It's filtering the transactions to only keep the ones that have a referenceId in their lines.
     */
    const operations = filteredTransactionByReferenceId({
      params: params as CheckNewAnomaliesSubParams[CheckNewAnomaliesType.transaction],
      options,
    });

    /**
     * * Process anomalies
     * Mapping over the transactions and adding anomalies to the lines.
     */
    for (const operation of operations) {
      if (operation?.journalEntry.lines) {
        const anomaliesResolvedLines: AnomalyErrorResult[] = [];
        const lines = operation.journalEntry.lines.map((line) => {
          if (line.refs && !isPlanBankAccount(line.account)) {
            const checkAnomalies: { code: AnomalyCode; check: () => AnomalyErrorResult[] }[] = [
              {
                code: AnomalyCode.referenceType,
                check: () =>
                  checkTypeReferenceAnomalies({
                    operation,
                    line,
                    categories: (params as CheckNewAnomaliesSubParams[CheckNewAnomaliesType.transaction]).categories,
                    data,
                    options,
                  }),
              },
              { code: AnomalyCode.taxTva, check: () => checkTaxTvaAnomalies({ operation, line, data, options }) },
              { code: AnomalyCode.loan, check: () => checkLoanAnomalies({ operation, line, data, options }) },
              { code: AnomalyCode.asset, check: () => checkAssetAnomalies({ operation, line, data, options }) },
            ];

            const anomaliesErrorsLine: AnomalyErrorResult[] = [];
            checkAnomalies.forEach((checkAnomaly) => {
              if (hasNoOptionOrOptionAnomalyCode(checkAnomaly.code)) {
                anomaliesErrorsLine.push(...checkAnomaly.check());
              }
            });

            if (anomaliesErrorsLine.length) {
              line.anomalies = anomaliesErrorsLine;
            } else {
              if (line.anomalies?.length) {
                anomaliesResolvedLines.push(...line.anomalies);
              }
              delete line.anomalies;
            }
          }
          return line;
        });

        operation.journalEntry.lines = lines;
        if (lines.filter((line) => !!line.anomalies?.length).length) {
          operationsErrors.push(operation);
        } else if (anomaliesResolvedLines.length) {
          anomaliesResolved.push(...anomaliesResolvedLines);
          operationsResolved.push(operation);
        }
      }
    }

    /**
     * * Formate anomalies result
     * It's adding the anomalies to the operationsErrors and operationsResolved.
     */
    operationsErrors.forEach((operation) =>
      operation?.journalEntry.lines?.forEach((line) => {
        if (line.anomalies?.length) {
          anomaliesErrors.push(...line.anomalies);
        }
      })
    );

    operationsWithErrorsAndResolved.push(
      ...operations.map((operation) => {
        if (operation.journalEntry.lines) {
          operation.journalEntry.lines = operation.journalEntry.lines.map((line) => {
            let lineAnomaly: JournalEntryLine | undefined;
            operationsErrors.forEach((operationError) => {
              lineAnomaly = operationError.journalEntry.lines?.find((lineAnomaly) => lineAnomaly.id === line.id);
            });

            let lineResolved: JournalEntryLine | undefined;
            operationsResolved.forEach((operationResolved) => {
              lineResolved = operationResolved.journalEntry.lines?.find((lineResolved) => lineResolved.id === line.id);
            });

            if (lineAnomaly) {
              return lineAnomaly;
            }
            if (lineResolved) {
              return lineResolved;
            }
            return line;
          });
        }
        return operation;
      })
    );
  }

  /**
   * * Process anomalies functional
   */
  if (!checkNewAnomaliesType || checkNewAnomaliesType === CheckNewAnomaliesType.functional) {
    const checkAnomalies: { code: AnomalyCode; check: () => AnomalyErrorResult[] }[] = [
      {
        code: AnomalyCode.objectIdLink,
        check: () => checkObjectIdLinkAnomalies({ data, options }),
      },
    ];

    checkAnomalies.forEach((checkAnomaly) => {
      if (hasNoOptionOrOptionAnomalyCode(checkAnomaly.code)) {
        anomaliesErrors.push(...checkAnomaly.check());
      }
    });
  }

  return {
    anomaliesErrorsSortByPriority: anomaliesSortByPriority(uniqBy(anomaliesErrors, "anomalyError")),
    operationsErrors,
    anomaliesResolvedSortByPriority: anomaliesSortByPriority(uniqBy(anomaliesErrors, "anomalyError")),
    operationsResolved,
    operationsWithErrorsAndResolved,
  };
};
