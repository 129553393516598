var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"editable-table"}},[_c('GenericTable',{attrs:{"headers":_vm.headersItems,"items":_vm.items,"itemsPerPage":_vm.itemsPerPage,"groupBy":_vm.groupBy,"hideGroupByCloseButton":_vm.hideGroupByCloseButton,"hideFooter":_vm.hideFooter !== undefined ? _vm.hideFooter : _vm.items.length < 11,"noDataTextHtml":_vm.noDataTextHtml,"isEnableCustomFooter":_vm.viewType === 'editable',"disableSort":_vm.disableSort,"highlightLastRow":_vm.highlightLastRow},scopedSlots:_vm._u([_vm._l((_vm.headersItems),function(ref){
var headerValue = ref.value;
return {key:("item." + headerValue),fn:function(ref){
var item = ref.item;
var value = ref.value;
var rowIndex = ref.rowIndex;
return [_c('div',{key:headerValue,staticClass:"item"},[(headerValue !== 'actions')?_vm._t(("item." + headerValue),null,{"headerValue":headerValue,"item":item,"value":value,"rowIndex":rowIndex,"isEditing":_vm.isEditingRows.find(function (index) { return index === rowIndex; }) !== undefined}):_c('div',{staticClass:"d-flex"},[(
              rowIndex === _vm.items.length - 1 ? !_vm.isDisableActionOnLastRow : true
            )?[(_vm.isEnableEditItem)?_c('div',[(
                  _vm.isEditingRows.find(function (index) { return index === rowIndex; }) ===
                    undefined &&
                  !(_vm.hideActionRow(rowIndex) && _vm.hideActionRow(rowIndex).edit)
                )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){return _vm.editItem(rowIndex)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Modifier")])]):_c('div',{staticClass:"action-edit"},[(
                    _vm.isEnableValidateItem &&
                    !(
                      _vm.hideActionRow(rowIndex) &&
                      _vm.hideActionRow(rowIndex).validate
                    )
                  )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){return _vm.validateItem(rowIndex)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-check ")])]}}],null,true)},[_c('span',[_vm._v("Valider")])]):_vm._e(),(
                    !(
                      _vm.hideActionRow(rowIndex) &&
                      _vm.hideActionRow(rowIndex).cancelEdit
                    )
                  )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.cancelEditItem(rowIndex)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-arrow-u-left-top ")])]}}],null,true)},[_c('span',[_vm._v("Annuler")])]):_vm._e()],1)],1):_vm._e(),(
                _vm.isEnableDeleteItem &&
                !(_vm.hideActionRow(rowIndex) && _vm.hideActionRow(rowIndex).delete)
              )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteItem(rowIndex)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Supprimer")])]):_vm._e()]:_vm._e()],2)],2)]}}}),{key:"footer",fn:function(){return [_c('div',{staticClass:"actions"},[(_vm.viewType === 'editable' && !_vm.hideAddButton)?_c('div',{staticClass:"actions-new"},[_c('v-btn',{staticClass:"actions-new-btn",attrs:{"text":"","color":"grey"},on:{"click":function($event){return _vm.addItem()}}},[_c('v-icon',{attrs:{"dense":"","left":"","color":"grey"}},[_vm._v("mdi-plus-circle")]),_vm._v(" "+_vm._s(_vm.addItemText)+" ")],1)],1):_vm._e()])]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }