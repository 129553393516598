var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form"},[(_vm.isFullYear)?[_c('v-row',{staticClass:"mb-1 justify-center"},[_c('v-col',[_c('v-alert',{attrs:{"type":"info","text":"","outlined":""}},[_vm._v("Référez-vous au décompte annuel du syndic de copropriété que vous avez reçu pour remplir le tableau suivant.")]),_c('EditableTable',{attrs:{"headers":_vm.headersFullYearCharge,"items":_vm.itemsFullYearCharge,"hideFooter":"","disableSort":""},scopedSlots:_vm._u([_vm._l((_vm.headersFullYearCharge),function(header){return {key:("item." + (header.value)),fn:function(ref){
var value = ref.value;
var rowIndex = ref.rowIndex;
return [(header.value === 'chargeType')?_c('div',{key:header.value,domProps:{"innerHTML":_vm._s(value)}}):_vm._e(),(header.value === 'expenses')?_c('v-text-field',{key:header.value,attrs:{"value":_vm.regularization.data.fullYearCharges[rowIndex].expenses,"type":"number","append-icon":"mdi-currency-eur"},on:{"input":function (event) { return _vm.$emit('updateRegularization', {
                    value: event,
                    path: ("data.fullYearCharges." + rowIndex + ".expenses"),
                  }); }}}):(header.value === 'percentage')?_c('v-text-field',{key:header.value,attrs:{"value":_vm.regularization.data.fullYearCharges[rowIndex].percentage,"type":"number","append-icon":"mdi-percent-outline"},on:{"input":function (event) { return _vm.$emit('updateRegularization', {
                    value: event,
                    path: ("data.fullYearCharges." + rowIndex + ".percentage"),
                  }); }}}):(header.value === 'totalAmount')?_c('v-text-field',{key:header.value,staticClass:"push-top",attrs:{"value":_vm.regularization.data.fullYearCharges[rowIndex].totalAmount,"type":"number","append-icon":"mdi-currency-eur","filled":"","rounded":"","readonly":""}}):_vm._e()]}}})],null,true)})],1)],1)]:[_c('v-alert',{attrs:{"type":"info","text":"","outlined":""}},[_vm._v("En cas de départ du locataire en cours d'année, le calcul se fait en deux étapes : "),_c('ul',[_c('li',[_vm._v(" D'abord, les charges communes de l'immeuble, basées sur le décompte annuel du syndic, ")]),_c('li',[_vm._v(" Puis, les consommations individuelles du locataire sur la période concernée, calculées à partir des relevés des compteurs individuels. ")])])]),_c('SectionTitle',{attrs:{"title":"Charges communes d'immeuble"}}),_c('v-row',{staticClass:"mb-1 justify-center"},[_c('v-col',[_c('EditableTable',{staticClass:"d-flex",attrs:{"headers":_vm.headersPartialYearCommonCharge,"items":_vm.itemsPartialYearCommonCharge,"hideFooter":"","disableSort":""},scopedSlots:_vm._u([_vm._l((_vm.headersPartialYearCommonCharge),function(header){return {key:("item." + (header.value)),fn:function(ref){
                  var value = ref.value;
                  var rowIndex = ref.rowIndex;
return [(header.value === 'chargeType')?_c('div',{key:header.value},[_vm._v(" "+_vm._s(value)+" ")]):_vm._e(),(header.value === 'expenses')?_c('v-text-field',{key:header.value,attrs:{"value":_vm.regularization.data.partialYearCommonCharges[rowIndex]
                  .expenses,"type":"number","append-icon":"mdi-currency-eur"},on:{"input":function (event) { return _vm.$emit('updateRegularization', {
                    value: event,
                    path: ("data.partialYearCommonCharges." + rowIndex + ".expenses"),
                  }); }}}):_vm._e(),(header.value === 'indexStart')?_c('v-text-field',{key:header.value,attrs:{"value":_vm.regularization.data.partialYearCommonCharges[rowIndex]
                  .indexStart,"type":"number","append-icon":"mdi-currency-eur"},on:{"input":function (event) { return _vm.$emit('updateRegularization', {
                    value: event,
                    path: ("data.partialYearCommonCharges." + rowIndex + ".indexStart"),
                  }); }}}):_vm._e(),(header.value === 'indexEnd')?_c('v-text-field',{key:header.value,attrs:{"value":_vm.regularization.data.partialYearCommonCharges[rowIndex]
                  .indexEnd,"type":"number","append-icon":"mdi-currency-eur"},on:{"input":function (event) { return _vm.$emit('updateRegularization', {
                    value: event,
                    path: ("data.partialYearCommonCharges." + rowIndex + ".indexEnd"),
                  }); }}}):(header.value === 'percentage')?_c('v-text-field',{key:header.value,attrs:{"value":_vm.regularization.data.partialYearCommonCharges[rowIndex]
                  .percentage,"type":"number","append-icon":"mdi-percent-outline"},on:{"input":function (event) { return _vm.$emit('updateRegularization', {
                    value: event,
                    path: ("data.partialYearCommonCharges." + rowIndex + ".percentage"),
                  }); }}}):(header.value === 'totalAmount')?_c('v-text-field',{key:header.value,staticClass:"push-top",attrs:{"value":_vm.regularization.data.partialYearCommonCharges[rowIndex]
                  .totalAmount,"type":"number","append-icon":"mdi-currency-eur","filled":"","rounded":"","readonly":""}}):_vm._e()]}}})],null,true)})],1)],1),_c('SectionTitle',{attrs:{"title":"Consommations individuelles"}}),_c('v-alert',{attrs:{"type":"info","text":"","outlined":""}},[_vm._v("Laissez à 0 pour les postes de charges qui ne vous concernent pas.")]),_c('v-row',{staticClass:"justify-center"},[_c('v-col',[_c('EditableTable',{attrs:{"headers":_vm.headersPartialYearIndividualCharge,"items":_vm.itemsPartialYearIndividualCharge,"hideFooter":"","disableSort":""},scopedSlots:_vm._u([_vm._l((_vm.headersPartialYearIndividualCharge),function(header){return {key:("item." + (header.value)),fn:function(ref){
                  var value = ref.value;
                  var rowIndex = ref.rowIndex;
return [(header.value === 'chargeType')?_c('div',{key:header.value},[_vm._v(" "+_vm._s(value)+" ")]):_vm._e(),(header.value === 'indexStart')?_c('v-text-field',{key:header.value,attrs:{"value":_vm.regularization.data.partialYearIndividualCharges[rowIndex]
                  .indexStart,"type":"number","append-icon":"mdi-clock-start"},on:{"input":function (event) { return _vm.$emit('updateRegularization', {
                    value: event,
                    path: ("data.partialYearIndividualCharges." + rowIndex + ".indexStart"),
                  }); }}}):_vm._e(),(header.value === 'indexEnd')?_c('v-text-field',{key:header.value,attrs:{"value":_vm.regularization.data.partialYearIndividualCharges[rowIndex]
                  .indexEnd,"type":"number","append-icon":"mdi-clock-end"},on:{"input":function (event) { return _vm.$emit('updateRegularization', {
                    value: event,
                    path: ("data.partialYearIndividualCharges." + rowIndex + ".indexEnd"),
                  }); }}}):_vm._e(),(header.value === 'consumption')?_c('v-text-field',{key:header.value,attrs:{"value":_vm.regularization.data.partialYearIndividualCharges[rowIndex]
                  .consumption,"type":"number","append-icon":"mdi-home-lightning-bolt-outline","readonly":""}}):_vm._e(),(header.value === 'unitCost')?_c('v-text-field',{key:header.value,attrs:{"value":_vm.regularization.data.partialYearIndividualCharges[rowIndex]
                  .unitCost,"type":"number","append-icon":"mdi-currency-eur"},on:{"input":function (event) { return _vm.$emit('updateRegularization', {
                    value: event,
                    path: ("data.partialYearIndividualCharges." + rowIndex + ".unitCost"),
                  }); }}}):(header.value === 'percentage')?_c('v-text-field',{key:header.value,attrs:{"value":_vm.regularization.data.partialYearIndividualCharges[rowIndex]
                  .percentage,"type":"number","append-icon":"mdi-percent-outline"},on:{"input":function (event) { return _vm.$emit('updateRegularization', {
                    value: event,
                    path: ("data.partialYearIndividualCharges." + rowIndex + ".percentage"),
                  }); }}}):(header.value === 'totalAmount')?_c('v-text-field',{key:header.value,staticClass:"push-top",attrs:{"value":_vm.regularization.data.partialYearIndividualCharges[rowIndex]
                  .totalAmount,"type":"number","append-icon":"mdi-currency-eur","readonly":"","filled":"","rounded":""}}):_vm._e()]}}})],null,true)})],1)],1)],_c('v-row',[_c('v-col',{staticClass:"submit-btn"},[_c('v-btn',{attrs:{"outlined":"","depressed":"","color":"primary","type":"button"},on:{"click":_vm.goBack}},[_vm._v(" Retour ")]),_c('v-btn',{staticClass:"white--text",attrs:{"color":"primary","type":"button"},on:{"click":_vm.goNext}},[_vm._v(" Suivant ")])],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }