import { ActivitiesService } from "@edmp/api";
import axios from "axios";

const api = "/api/v1/activities";

class ActivitiesService {
  async list(params: ActivitiesService.ListIn) {
    return axios
      .get<ActivitiesService.ListOut>(`${api}`, { params })
      .then((res) => {
        return res.data;
      });
  }

  async get(params: ActivitiesService.GetIn) {
    return axios
      .get<ActivitiesService.GetOut>(`${api}/${params.id}`, { params })
      .then((res) => {
        return res.data;
      });
  }

  async update(activityUpdate: ActivitiesService.UpdateIn) {
    return axios
      .put<ActivitiesService.UpdateOut>(
        `${api}/${activityUpdate.id}`,
        activityUpdate
      )
      .then((res) => {
        return res.data;
      });
  }
  async addImage(
    params: Omit<ActivitiesService.AddImageIn, "files"> & {
      files: Object;
    }
  ) {
    const blobInfos = new Blob(
      [
        JSON.stringify({
          id: params.id,
          productId: params.productId,
        }),
      ],
      {
        type: "application/json",
      }
    );

    // let files;

    const formData = new FormData();
    formData.append("infos", blobInfos);

    for (const [key, file] of Object.entries(params.files)) {
      if (key === "file64") {
        const blobFile = new Blob(
          [
            JSON.stringify({
              key: key,
              file: file,
            }),
          ],
          {
            type: "application/json",
          }
        );
        formData.append(key, blobFile);
      } else {
        formData.append(key, file.value);
      }
    }

    return axios
      .post<ActivitiesService.AddImageIn>(
        `${api}/${params.id}/addImage`,
        formData
      )
      .then((res) => {
        return res.data;
      });
  }
  async deleteImage(params: ActivitiesService.DeleteImageIn) {
    return axios
      .put<ActivitiesService.DeleteImageOut>(
        `${api}/${params.id}/deleteImage`,

        params
      )
      .then((res) => {
        return res.data;
      });
  }
}

// Export a singleton instance in the global namespace
export const activitiesService = new ActivitiesService();
