import { SubscriptionsModel } from "@edmp/api";

export const PartnersAreaEventType = "PartnersAreaEvent" as const;

export enum PartnersAreaEventCode {
  ENTER = "enter",
  QUIT = "quit",
  CLICK_INVEST_IN_OLD_RENOVATED = "click-investInOldRenovated",
  CLICK_CONSULT_EXPERT = "click-consultExpert",
  CLICK_BOOST_ENERGY_SORE = "click-boostEnergyScore",
  CLICK_BENEFIT_PERSONALIZED_PROFESSIONAL_ADVICE = "click-BenefitPersonalizedProfessionalAdvice",
  CLICK_LAUNCH_REAL_ESTATE_PROJECT = "click-LaunchRealEstateProject",
}

export type PartnersAeraEventData<
  Code extends PartnersAreaEventCode = PartnersAreaEventCode
> = {
  code: Code;
  userId: string;
  productId: string;
  subscriptionPlan: SubscriptionsModel.Subscription["plan"]["type"] | "unknown";
  date: string;
} & (
  | { code: PartnersAreaEventCode.ENTER }
  | { code: PartnersAreaEventCode.QUIT }
  | { code: PartnersAreaEventCode.CLICK_INVEST_IN_OLD_RENOVATED }
  | { code: PartnersAreaEventCode.CLICK_CONSULT_EXPERT }
  | { code: PartnersAreaEventCode.CLICK_BOOST_ENERGY_SORE }
  | {
      code: PartnersAreaEventCode.CLICK_BENEFIT_PERSONALIZED_PROFESSIONAL_ADVICE;
    }
  | {
      code: PartnersAreaEventCode.CLICK_LAUNCH_REAL_ESTATE_PROJECT;
    }
);

export type PartnersAeraEvent = CustomEvent<PartnersAeraEventData>;

export function dispatchPartnersAreaEvent(data: PartnersAeraEventData) {
  dispatchEvent(
    new CustomEvent<PartnersAeraEventData>(PartnersAreaEventType, {
      detail: data,
    })
  );
}
