




































































import {
  DocumentModelType,
  DocumentFileType,
  RentalAgreement,
} from "@edmp/api";
import { defineComponent, ref } from "@vue/composition-api";
// import { rentalAgreementsStore } from "@/store";
import { rentalsService } from "@/services";

export default defineComponent({
  name: "RentalAgreementsPreviewMail",
  props: {
    rentalAgreement: {
      type: Object as () => RentalAgreement &
        Required<Pick<RentalAgreement, "options">>,
      required: true,
    },
    typeOfMail: {
      type: String,
      required: true,
    },
    titleType: {
      type: String,
      required: true,
    },
  },
  setup() {
    const mailText = ref("");
    // const { rentalAgreement, typeOfMail } = props;
    const text = ref({
      subject: {
        rentReceipt: "Votre quittance de [Mois concerné + Année en cours]",
        rentCall: "Votre appel de loyer de [Mois concerné + Année en cours]",
        unpaidRentCall: "Relance de loyer impayé",
      },
      html: {
        rentReceipt:
          "Bonjour [Prénom du locataire],<br><br>Nous vous adressons en pièce jointe la <strong>quittance de loyer</strong> pour la période <strong>[Mois concerné + Année en cours]</strong> d'un montant de [Montant]€ pour votre location située au [Adresse du bien loué].<br><br>Cette notification signifie que votre propriétaire a bien reçu le règlement.<br><br>Bonne journée et à bientôt",
        rentCall:
          "Bonjour [Prénom du locataire],<br><br>Nous vous adressons en pièce jointe l'<strong>appel de loyer</strong> pour la période de <strong>[Mois concerné + Année en cours]</strong> d'un montant de <strong>[Montant]</strong>€ pour votre location située au [Adresse du bien loué].<br><br>Vous avez jusqu'au [Date de paiement] pour régler cette somme à votre propriétaire.<br><br>Bonne journée et à bientôt",
        unpaidRentCall:
          "Bonjour [Prénom et nom du locataire],<br><br>Votre loyer d'un montant de <strong>[Montant]</strong>€ pour la période de <strong>[Mois concerné + Année en cours]</strong> n'a pas été réglé dans les temps, conformément au bail signé à l'entrée dans les lieux.Merci de régulariser rapidement votre situation par virement (sous 8 jours à compter de la réception de cet email).<br><br>Nous vous adressons en pièce jointe <strong>l'appel de loyer</strong> de cette location pour la période.<br><br>Cordialement,",
      },
    });
    // function formatMessage(text: string): string {
    //   return text
    //     .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
    //     .replace(/\n/g, "<br>")
    //     .replace("Votre quittance", "<strong>Objet</strong> : Votre quittance")
    //     .replace("Votre appel", "<strong>Objet</strong> : Votre appel")
    //     .replace("Relance", "<strong>Objet </strong>: Relance");
    // }
    const downloadModel = async (type: string) => {
      if (type === "tenant.rent-receipt") {
        await rentalsService.agreements.downloadDocumentModel({
          modelType: DocumentModelType.RENT_RECEIPT,
          fileType: DocumentFileType.PDF,
        });
      } else if (type === "tenant.rent-call") {
        await rentalsService.agreements.downloadDocumentModel({
          modelType: DocumentModelType.RENT_CALL,
          fileType: DocumentFileType.PDF,
        });
      } else if (type === "tenant.unpaid-rent-call") {
        await rentalsService.agreements.downloadDocumentModel({
          modelType: DocumentModelType.REMINDER_OF_UNPAID_RENT,
          fileType: DocumentFileType.PDF,
        });
      }
    };
    /**
    async function init() {
        mailText.value = formatMessage(
          await rentalAgreementsStore.getPreviewMail({
            rentalAgreementId: rentalAgreement.id,
            typeOfMail,
          })
        );
    } 
    */

    // watch(
    //   () => [typeOfMail],
    //   // () => init(),
    //   { deep: true }
    // );
    // onMounted(() => {
    //   init();
    // });

    return {
      mailText,
      downloadModel,
      text,
    };
  },
});
