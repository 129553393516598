






import { TaskGroupLocal } from "@/models";
import { defineComponent, PropType } from "@vue/composition-api";
import Group from "./TaskGroupHeader.vue";

export default defineComponent({
  name: "TaskGroupCard",
  components: { Group },
  props: {
    taskGroupLocal: {
      type: Object as PropType<TaskGroupLocal>,
      required: true,
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {};
  },
});
