import {
  bankAccountsStore,
  documentsStore,
  partnersStore,
  realEstateAssetsStore,
  realEstateLoansStore,
  rentalAgreementsStore,
  tenantsStore,
  transactionsStore,
} from "@/store";
import {
  Transaction,
  AnomalyOptions,
  checkNewAnomalies,
  Suggestion,
  JournalComposedEntry,
  CheckNewAnomaliesType,
  CheckNewAnomaliesSubParams,
  AnomalyError,
  TransactionImportType,
} from "@edmp/api";
import { cloneDeep, flatMap, uniqBy } from "lodash";

export const useAnomalies = () => {
  const checkDisableAnomalies = (anomaly: AnomalyError) =>
    anomaly !== AnomalyError.loanInsuranceEnableRequired &&
    anomaly !== AnomalyError.loanLedgerAccount616600Required &&
    anomaly !== AnomalyError.loanLedgerAccount661110Required &&
    anomaly !== AnomalyError.objectIdLinkRentalUnitRequired &&
    anomaly !==
      AnomalyError.loanRepaymentDeadlineAmountEqualTransactionAmountRequired &&
    anomaly !==
      AnomalyError.loanRepaymentDeadlineDateEqualTransactionDateRequired;

  /**
   * It checks for anomalies in a list of transactions
   * @param {{transactions?: Transaction[]; options?: AnomalyOptions;}} params -
   * @returns An array of Anomalies
   */
  const checkOnNewAnomalies = async ({
    checkNewAnomaliesType,
    transactions,
    options,
  }: {
    checkNewAnomaliesType?: CheckNewAnomaliesType;
    transactions?: Transaction[];
    options?: AnomalyOptions;
  }) => {
    let params: CheckNewAnomaliesSubParams[CheckNewAnomaliesType] = {};

    if (
      !checkNewAnomaliesType ||
      checkNewAnomaliesType === CheckNewAnomaliesType.transaction
    ) {
      /* Checking for anomalies in a list of transactions */
      let filteredTransactions: Transaction[] = [];
      if (!transactions) {
        if (options) {
          for (const anomalyValue of Object.values(options)) {
            if (anomalyValue.referenceId) {
              filteredTransactions.push(
                ...flatMap(cloneDeep(transactionsStore.transactions)).filter(
                  (transaction) =>
                    transaction.operations?.journalEntry?.lines?.filter(
                      (line) =>
                        line.refs &&
                        line.refs
                          ?.map((ref) => ref.referredId)
                          .includes(anomalyValue.referenceId as string)
                    ).length !== 0
                )
              );
            } else {
              filteredTransactions.push(
                ...flatMap(cloneDeep(transactionsStore.transactions))
              );
            }
          }
          filteredTransactions = uniqBy(filteredTransactions, "id");
        } else {
          flatMap(cloneDeep(transactionsStore.transactions));
        }
      } else {
        filteredTransactions = cloneDeep(transactions);
      }

      /* Getting all the categories from the store. */
      const categories: Suggestion[] = [];
      for (const parentCategories of Object.values(
        transactionsStore.categoriesList
      )) {
        categories.push(...parentCategories);
      }

      params = {
        ...params,
        operations: filteredTransactions
          .filter(
            (transaction) =>
              !!transaction.operations &&
              transaction.type !== TransactionImportType.AMORTIZATION &&
              transaction.type !== TransactionImportType.ACQUISITION
          )
          .map((transaction) => transaction.operations as JournalComposedEntry),
        categories: categories,
      };
    }

    if (
      !checkNewAnomaliesType ||
      checkNewAnomaliesType === CheckNewAnomaliesType.functional
    ) {
      params = {
        ...params,
      };
    }

    /* Checking for anomalies in a list of transactions */
    return checkNewAnomalies<CheckNewAnomaliesType>({
      checkNewAnomaliesType,
      params,
      data: {
        bankAccounts: bankAccountsStore.bankAccounts,
        realEstateAssets: realEstateAssetsStore.realEstateAssets,
        rentalUnits: realEstateAssetsStore.rentalUnits,
        rentalAgreements: rentalAgreementsStore.rentalAgreements,
        tenants: tenantsStore.tenants,
        partners: partnersStore.partners,
        documents: documentsStore.documents,
        realEstateLoans: realEstateLoansStore.realEstateLoans,
      },
      options,
    });
  };

  return {
    checkOnNewAnomalies,
    checkDisableAnomalies,
  };
};
