var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.transactionState.isUpdatingCategorization)?_c('v-progress-linear',{staticClass:"my-2 ml-md-6",staticStyle:{"min-width":"220px"},attrs:{"color":"primary","indeterminate":""}}):_vm._e(),(!_vm.transactionState.isUpdatingCategorization)?_c('div',{staticClass:"my-2 d-flex"},[(_vm.computedSuggestionCategories.length)?_c('v-icon',{staticClass:"mr-3 mr-md-0",attrs:{"color":"success","small":""}},[_vm._v(" mdi-lightbulb-on-outline ")]):_vm._e(),(_vm.computedCategories.length)?_c('div',{staticClass:"transaction-card-categories my-2"},_vm._l((_vm.computedCategories),function(category){return _c('div',{key:((_vm.transactionState.transaction.id) + "-" + (category.accountName)),staticClass:"transaction-card-category"},[_c('span',[_c('v-expansion-panels',{attrs:{"flat":true}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"id":"test_expansion_header_transaction"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{staticClass:"icon"},[_vm._v("$expand")])]},proxy:true}],null,true)},[_c('span',{staticClass:"header mt-1"},[_c('strong',{staticClass:"transaction-card-category",attrs:{"id":"transaction-category-name"}},[_vm._v(" "+_vm._s(category.accountName)+" ")])])]),_c('v-expansion-panel-content',[(category.realEstateAsset)?_c('v-chip',{staticClass:"chip",attrs:{"small":"","outlined":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v(" mdi-home-outline ")]),(category.realEstateAsset)?_c('span',_vm._g(_vm._b({staticClass:"textOverflow"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.categorizationUse.getAttribute( "realEstateAsset", category.realEstateAsset ))+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.categorizationUse.getAttribute( "realEstateAsset", category.realEstateAsset )))])])],1):_vm._e(),(category.fixedAsset)?_c('v-chip',{staticClass:"chip",attrs:{"small":"","outlined":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v(" mdi-home-clock-outline ")]),(category.fixedAsset)?_c('span',_vm._g(_vm._b({staticClass:"textOverflow"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.categorizationUse.getAttribute( "fixedAsset", category.fixedAsset ))+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.categorizationUse.getAttribute( "fixedAsset", category.fixedAsset )))])])],1):_vm._e(),(category.rentalAgreement)?_c('v-chip',{staticClass:"chip",attrs:{"small":"","outlined":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-key-outline ")]),(category.rentalAgreement)?_c('span',_vm._g(_vm._b({staticClass:"textOverflow"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.categorizationUse.getAttribute( "rentalAgreement", category.rentalAgreement ))+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.categorizationUse.getAttribute( "rentalAgreement", category.rentalAgreement ))+" ")])])],1):_vm._e(),(category.tenant)?_c('v-chip',{staticClass:"chip",attrs:{"small":"","outlined":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-key-outline ")]),(category.tenant)?_c('span',_vm._g(_vm._b({staticClass:"textOverflow"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.categorizationUse.getAttribute( "tenant", category.tenant ))+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.categorizationUse.getAttribute( "tenant", category.tenant ))+" ")])])],1):_vm._e(),(category.partner)?_c('v-chip',{staticClass:"chip",attrs:{"small":"","outlined":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-outline ")]),(category.partner)?_c('span',_vm._g(_vm._b({staticClass:"textOverflow"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.categorizationUse.getAttribute( "partner", category.partner ))+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.categorizationUse.getAttribute( "partner", category.partner ))+" ")])])],1):_vm._e(),(category.realEstateLoan)?_c('v-chip',{staticClass:"chip",attrs:{"small":"","outlined":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v(" mdi-home-percent-outline ")]),(category.realEstateLoan)?_c('span',_vm._g(_vm._b({staticClass:"textOverflow"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.categorizationUse.getAttribute( "realEstateLoan", category.realEstateLoan ))+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.categorizationUse.getAttribute( "realEstateLoan", category.realEstateLoan )))])])],1):_vm._e(),(category.supportingDocument)?_c('v-chip',{staticClass:"chip",attrs:{"small":"","outlined":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(category.supportingDocument)?_c('a',{staticClass:"supporting-document",attrs:{"href":_vm.getSupportingDocument(category.supportingDocument),"target":"_blank"}},[_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v(" mdi-folder-outline ")]),(category.supportingDocument)?_c('span',_vm._g(_vm._b({staticClass:"textOverflow"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.categorizationUse.getAttribute( "supportingDocument", category.supportingDocument ))+" ")]):_vm._e()],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.categorizationUse.getAttribute( "supportingDocument", category.supportingDocument ))+" ")])])],1):_vm._e(),(category.beneficiary)?_c('v-chip',{staticClass:"chip",attrs:{"small":"","outlined":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v(" mdi-hand-extended ")]),(category.beneficiary)?_c('span',_vm._g(_vm._b({staticClass:"textOverflow"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.categorizationUse.getAttribute( "beneficiary", category.beneficiary ))+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.categorizationUse.getAttribute( "beneficiary", category.beneficiary )))])])],1):_vm._e()],1)],1)],1)],1),_c('span',{staticClass:"transaction-card-category-amount ml-3 ml-sm-5 ml-md-10"},[_vm._v(" "+_vm._s(_vm.$n(category.amount, "currency", "fr-FR"))+" ")])])}),0):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }