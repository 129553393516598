import { render, staticRenderFns } from "./RegisterActivityDetailsLMNP.vue?vue&type=template&id=f4b35f64&"
import script from "./RegisterActivityDetailsLMNP.vue?vue&type=script&lang=ts&"
export * from "./RegisterActivityDetailsLMNP.vue?vue&type=script&lang=ts&"
import style0 from "./RegisterActivityDetailsLMNP.vue?vue&type=style&index=0&id=f4b35f64&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VAlert,VBtn,VCard,VCardActions,VDialog,VForm,VIcon,VProgressCircular,VRadio,VRadioGroup,VRow,VSpacer,VTextField,VToolbar})
