























































































































































































































import {
  computed,
  defineComponent,
  onBeforeMount,
  ref,
  watch,
} from "@vue/composition-api";
import { useRealEstateAmortisation } from "../realEstateAmortisation/realEstateAmortisation.usable";
import { HelpingMessage } from "@/components/atom/helping";
import {
  NatureLabel,
  getMoment,
  RealEstateAsset,
  FixedAsset,
  getFixedAssetTypeText,
} from "@edmp/api";
import PageHeader from "@/components/atom/PageHeader.vue";
import router from "@/router";
import { ROUTE_NAMES } from "@/router/routes";
import { ArticleEnum } from "@/composables/crisp.usable";
import {
  productsStore,
  fixedAssetAmortisationsStore,
  realEstateAmortisationsStore,
  accountingPeriodsStore,
} from "@/store";
import { useFixedAssetAmortisation } from "../fixedAssetAmortisation/fixedAssetAmortisation.usable";

export default defineComponent({
  name: "Amortisations",
  components: {
    PageHeader,
    HelpingMessage,
  },
  setup() {
    const isLoading = ref(false);
    const usables = useRealEstateAmortisation();
    const usablesFixedAsset = useFixedAssetAmortisation();
    const gotoRealEstateAmortisation = (realEstate: RealEstateAsset) => {
      if (accountingPeriodsStore.isLMNP) {
        router.push({
          name: ROUTE_NAMES.RealEstateLMNP,
          params: {
            productId: realEstate.productId,
            realEstateAssetId: realEstate.id,
          },
          query: { tabIndex: "2" },
        });
      } else {
        router.push({
          name: ROUTE_NAMES.RealEstate,
          params: {
            productId: realEstate.productId,
            realEstateAssetId: realEstate.id,
          },
          query: { focus: "realEstateAmortisation-section" },
        });
      }
    };
    const gotoFixedAssetAmortisation = (fixedAsset: FixedAsset) => {
      router.push({
        name: ROUTE_NAMES.FixedAsset,
        params: {
          productId: fixedAsset.productId,
          fixedAssetId: fixedAsset.id,
        },
        query: { focus: "fixedAssetAmortisation-section" },
      });
    };
    const init = async () => {
      realEstateAmortisationsStore.fetchRealEstateAmortisations(
        productsStore.currentId
      );
      fixedAssetAmortisationsStore.fetchFixedAssetAmortisations(
        productsStore.currentId
      );
    };
    onBeforeMount(async () => {
      await init();
    });
    watch(
      [productsStore.currentId],
      async () => {
        await init();
      },

      { deep: true }
    );
    return {
      ...usables,
      ...usablesFixedAsset,
      NatureLabel,
      getMoment,
      gotoRealEstateAmortisation,
      gotoFixedAssetAmortisation,
      getFixedAssetTypeText,
      ArticleEnum,
      isLMNP: computed(() => accountingPeriodsStore.isLMNP),
      isLoading,
    };
  },
});
