















import TaskGroupCard from "./taskGroup/TaskGroupCard.vue";
import { tasksStore } from "@/store";
import { TaskGroupCode, TaskStatus } from "@edmp/api";
import { computed, defineComponent, PropType } from "@vue/composition-api";
import { uniqBy } from "lodash";

export default defineComponent({
  name: "TaskGroupResumeNavigateTo",
  components: {
    TaskGroupCard,
  },
  props: {
    taskGroupCodes: {
      type: Array as PropType<TaskGroupCode[]>,
      required: true,
    },
  },
  setup(props) {
    const startWellTaskGroups = computed(() => {
      return tasksStore.startWellTaskGroup;
    });
    const closureCustomTaskGroups = computed(() => {
      return tasksStore.closureCustomTaskGroup;
    });
    const eventAccountingPeriodClosureTaskGroup = computed(() => {
      return tasksStore.eventAccountingPeriodClosureTaskGroup;
    });

    const taskGroups = computed(() =>
      uniqBy(
        [
          ...startWellTaskGroups.value,
          ...closureCustomTaskGroups.value,
          ...eventAccountingPeriodClosureTaskGroup.value,
        ],
        "groupCode"
      ).filter(({ groupCode }) =>
        props.taskGroupCodes.includes(groupCode as TaskGroupCode)
      )
    );

    return { taskGroups, TaskStatus };
  },
});
