


























































































































































































































































































































































































































import { YesNoSwitch } from "@/components/atom/switch";
import {
  defineComponent,
  onMounted,
  PropType,
  ref,
  watch,
} from "@vue/composition-api";
import SectionTitle from "@/components/atom/SectionTitle.vue";
import DatePicker from "@/components/atom/DatePicker.vue";
import { useRents } from "@/components/core/rentalAgreements/rentalAgreementFlow/rents.usable";
import { VForm } from "@/models";
import { RentalAgreement, RentIndexationTypeParamEnum } from "@edmp/api";
import { rentalsService } from "@/services";

export default defineComponent({
  name: "RentalAgreementFinancial",
  components: { DatePicker, SectionTitle, YesNoSwitch },
  props: {
    rentalAgreement: {
      type: Object as PropType<RentalAgreement>,
      required: true,
    },
    fetchingInseeData: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, context) {
    const {
      amountOfTVA,
      paymentChargeMethodItems,
      paymentTermsItems,
      rentPayedForItems,
      rentTypeItems,
      quarterlyPeriodItems,
    } = useRents();
    const hasTenseArea = ref(
      !!props.rentalAgreement.financialConditions &&
        !!props.rentalAgreement.financialConditions.tenseArea
    );
    const hasIndexationClause = ref(
      !!props.rentalAgreement.financialConditions.indexation
    );
    const hasTVA = ref(false);
    const indexData = ref();

    watch(hasTenseArea, () => {
      if (hasTenseArea.value === false) {
        context.emit("updateRentalAgreement", {
          value: undefined,
          path: "financialConditions.tenseArea",
        });
      } else {
        context.emit("updateRentalAgreement", {
          value: {
            monthlyRentReference: null,
            monthlyRentReferenceIncreased: null,
            supplementRent: null,
            supplementRentJustification: null,
          },
          path: "financialConditions.tenseArea",
        });
      }
    });

    watch(hasIndexationClause, () => {
      if (hasIndexationClause.value === false) {
        context.emit("updateRentalAgreement", {
          value: undefined,
          path: "financialConditions.indexation",
        });
      } else {
        context.emit("updateRentalAgreement", {
          value: {
            type: RentIndexationTypeParamEnum.IRL_metro,
          },
          path: "financialConditions.indexation",
        });
      }
    });
    const isSubmitting = ref(false);

    const goBack = () => {
      context.emit("goBack");
    };

    async function validate() {
      if ((context.refs.form as VForm).validate()) {
        context.emit("validate");
      }
    }

    const changeIrlType = async (irlType) => {
      const arrayData = await rentalsService.agreements.getListIndexations(
        irlType
      );
      const indexes: String[] = [];
      for (const data of arrayData) {
        indexes.push(data.indexData);
      }
      indexData.value = indexes;
    };

    onMounted(async () => {
      const arrayData = await rentalsService.agreements.getListIndexations(
        props.rentalAgreement.financialConditions.indexation?.type ??
          RentIndexationTypeParamEnum.IRL_metro
      );
      const indexes: String[] = [];
      for (const data of arrayData) {
        indexes.push(data.indexData);
      }
      indexData.value = indexes;
    });

    return {
      goBack,
      validate,
      amountOfTVA,
      paymentChargeMethodItems,
      paymentTermsItems,
      rentPayedForItems,
      rentTypeItems,
      quarterlyPeriodItems,
      isSubmitting,
      hasTenseArea,
      hasTVA,
      hasIndexationClause,
      indexData,
      changeIrlType,
    };
  },
});
