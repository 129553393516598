import { ref } from "@vue/composition-api";

export function useRegularization() {
  const itemsBaseFullYearCharge = {
    expenses: 0,
    percentage: 100,
    totalAmount: "0.00",
  };

  const itemsBasePartialYearCommonCharge = {
    expenses: 0,
    percentage: 100,
    totalAmount: "0.00",
  };

  const itemsBasePartialYearIndividualCharge = {
    indexStart: 0,
    indexEnd: 0,
    consumption: 0,
    unitCost: 0,
    percentage: 100,
    totalAmount: "0.00",
  };

  const headersFullYearCharge = [
    { text: "Nature de la charge", value: "chargeType" },
    { text: "Dépenses", value: "expenses" },
    {
      text: "Pourcentage à récupérer",
      info: "Ce champ indique la part des dépenses que le propriétaire peut récupérer auprès des locataires.<br />Par défaut, il est fixé à 100 %, mais le propriétaire peut choisir de ne pas récupérer la totalité de certaines charges,<br /> en ajustant ce pourcentage selon les règles de répartition définies dans le bail ou la copropriété.",
      value: "percentage",
    },
    { text: "Montant des dépenses récupérables", value: "totalAmount" },
  ];

  const itemsFullYearCharge = ref([
    {
      chargeType:
        "Charges d'immeuble selon décompte dont eau, électricité, gaz, etc...",
      ...itemsBaseFullYearCharge,
    },
    {
      chargeType: "Taxe d'enlèvement des ordures ménagères",
      ...itemsBaseFullYearCharge,
    },
    {
      chargeType: "Redevance d'assainissement",
      ...itemsBaseFullYearCharge,
    },
    {
      chargeType: "Entretien chaudière",
      ...itemsBaseFullYearCharge,
    },
  ]);

  const headersPartialYearCommonCharge = [
    { text: "Nature de la charge", value: "chargeType" },
    { text: "Dont locatif annuel", value: "expenses" },
    {
      text: "Pourcentage à récupérer",
      info: "Ce champ indique la part des dépenses que le propriétaire peut récupérer auprès des locataires.<br />Par défaut, il est fixé à 100 %, mais le propriétaire peut choisir de ne pas récupérer la totalité de certaines charges,<br /> en ajustant ce pourcentage selon les règles de répartition définies dans le bail ou la copropriété.",
      value: "percentage",
    },
    {
      text: "Montant proratisé",
      value: "totalAmount",
      info: "Montant calculé par rapport à la période renseignée en étape 1",
    },
  ];

  const itemsPartialYearCommonCharge = ref([
    {
      chargeType:
        "Charges d'immeuble selon décompte dont eau, électricité, gaz, etc...",
      ...itemsBasePartialYearCommonCharge,
    },
    {
      chargeType: "Taxe d'enlèvement des ordures ménagères",
      ...itemsBasePartialYearCommonCharge,
    },
    {
      chargeType: "Redevance d'assainissement",
      ...itemsBasePartialYearCommonCharge,
    },
    {
      chargeType: "Entretien chaudière",
      ...itemsBasePartialYearCommonCharge,
    },
  ]);

  const headersPartialYearIndividualCharge = [
    { text: "Nature de la charge", value: "chargeType" },
    { text: "Indice de début", value: "indexStart" },
    { text: "Indice de fin", value: "indexEnd" },
    {
      text: "Consommation",
      value: "consumption",
      info: "Consommation = Indice de fin - Indice de début",
    },
    {
      text: "Prix à l'unité",
      value: "unitCost",
      info: "Prix à l'unité = Total des dépenses de la copropriété par poste de charge / Tantièmes de la copropriété par poste de charge",
    },
    {
      text: "Pourcentage à récupérer",
      info: "Ce champ indique la part des dépenses que le propriétaire peut récupérer auprès des locataires.<br />Par défaut, il est fixé à 100 %, mais le propriétaire peut choisir de ne pas récupérer la totalité de certaines charges,<br /> en ajustant ce pourcentage selon les règles de répartition définies dans le bail ou la copropriété.",
      value: "percentage",
    },
    {
      text: "Montant réel",
      value: "totalAmount",
      info: "Montant réel = Consommation x Prix à l'unité x (Pourcentage à récupérer / 100)",
    },
  ];

  const itemsPartialYearIndividualCharge = ref([
    {
      chargeType: "Eau froide (en m³)",
      ...itemsBasePartialYearIndividualCharge,
    },
    {
      chargeType: "Eau chaude (en m³)",
      ...itemsBasePartialYearIndividualCharge,
    },
    {
      chargeType: "Électricité (en kWh)",
      ...itemsBasePartialYearIndividualCharge,
    },
    {
      chargeType: "Gaz (en m³)",
      ...itemsBasePartialYearIndividualCharge,
    },
  ]);

  return {
    itemsBaseFullYearCharge,
    itemsBasePartialYearCommonCharge,
    itemsBasePartialYearIndividualCharge,
    headersFullYearCharge,
    itemsFullYearCharge,
    headersPartialYearCommonCharge,
    itemsPartialYearCommonCharge,
    headersPartialYearIndividualCharge,
    itemsPartialYearIndividualCharge,
  };
}
