































import { defineComponent, ref } from "@vue/composition-api";
import SectionTitle from "@/components/atom/SectionTitle.vue";
import CheckBoxCard from "@/components/atom/CheckBoxCard.vue";

export default defineComponent({
  name: "RentalAgreementType",
  components: { CheckBoxCard, SectionTitle },
  setup(props, context) {
    const agreementType = ref("exist");

    const goBack = () => {
      context.emit("goBack");
    };
    const goNext = () => {
      if (agreementType.value === "new") {
        window.open("https://www.ownily.fr/services/bail/choix-bail", "_blank");
        return;
      }

      context.emit("goNext");
    };

    return { agreementType, goBack, goNext };
  },
});
